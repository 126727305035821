import React , { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import HeroSection from "../components/HeroSection";
import Services from "../components/Services"
import Trusted from "../components/Trusted"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';
// import LCR459 from "../../images1/LCR459.PNG"
// import Measuring from "../../images1/google.png"
// import { Block, CenterFocusStrong } from '@material-ui/icons';

// import Avatar from "./SupportEngine/Avatar"
// import SupportWindow from './SupportEngine/SupportWindow/index'
import ScrollableFeed from "react-scrollable-feed"
// 7min
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';

import Chat from './Chat';
import SignIn from './SignIn';

import { useAuthState } from 'react-firebase-hooks/auth'


import { useCollectionData } from 'react-firebase-hooks/firestore';
// 7 min
import "bootstrap/dist/css/bootstrap.min.css"
import "font-awesome/css/font-awesome.css";
import {io} from "socket.io-client"
const socket= io("http://localhost:4000");



firebase.initializeApp({
  apiKey: "AIzaSyBBh2GCpA1LFu4gWvAolHVp18MbQkDJbnM",
  authDomain: "shining-glyph-307621.firebaseapp.com",
  databaseURL: "https://shining-glyph-307621-default-rtdb.firebaseio.com",
  projectId: "shining-glyph-307621",
  storageBucket: "shining-glyph-307621.appspot.com",
  messagingSenderId: "372111186576",
  appId: "1:372111186576:web:6e4cf79b516c9ab6341615",
  measurementId: "G-WVFLG1ZQ83"
})

const auth = firebase.auth();


const Home = () => {

  // const [user, setUser] = useState({});
  const [ setUser] = useState({});

  const [users, setUsers] = useState([]);

  const [messages, setMessages] = useState([])
  const [user] = useAuthState(auth);
  useEffect(() => {
    socket.on("users", (users)=>{
      console.log(users,"number of users")
      const messagesArr= [];
      for(const {userId, username} of users){
        const newMessage= {type:"userStatus", userId, username};
        messagesArr.push(newMessage)
      }
      setMessages([...messages, ...messagesArr])
      setUsers({users})

    })
    socket.on("session", ({userId, username}) => {
      setUser({userId, username});
    })
    socket.on("user connected",({userId, username}) => {
      const newMessage={type:"UserStatus", userId, username}
      setMessages([...messages,newMessage])
    })
    socket.on("new message",({ userId, username, message})=>{
      const newMessage= {
        type:"message",
        userId: userId,
        username: username,
        message,
      };
      setMessages([...messages, newMessage])
    })
  },[socket, messages]);

 

  const data = {
    name: "Microdyne Systems",
  };


  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);
  const [visible, setVisible] = useState(false)

 

  function useOutsideAlerter(ref) {
      useEffect(() => {
          function handleClickOutside(event) {
              if (ref.current && !ref.current.contains(event.target)) {
                  setVisible(false)
              }
          }
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
              document.removeEventListener("mousedown", handleClickOutside);
          };
      }, [ref]);}

  return (
  
      <>
      <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={100}
      slidesPerView={1}
      navigation
      autoplay={{delay:4000}}
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
      effect= "coverflow"
      // coverflowEffect= {{
      //   rotate: 0,
      //   stretch: 0,
      //   depth: 200,
      //   modifier: 1,
      //   slideShadows: false,
      // }}
    >
      <SwiperSlide>        <img src={"https://www.kusamelectrical.com/img/slides/flexslider/1.jpg"} style={{ width: 900, height: 350 , display: "block", margin: "auto" }}alt="Coding Beauty logo"></img></SwiperSlide>
      <SwiperSlide>        <img src={"https://www.kusamelectrical.com/img/slides/flexslider/2.jpg"} style={{ width: 900, height: 350, display: "block", margin: "auto" }}alt="Coding Beauty logo"></img></SwiperSlide>
      <SwiperSlide>      <div>  <img src={"https://www.kusamelectrical.com/img/slides/flexslider/1.jpg"} style={{ width: 900, height: 350 ,display: "block", margin: "auto"}} alt="Coding Beauty logo"></img> </div></SwiperSlide>
      <SwiperSlide>        <img src={"https://www.kusamelectrical.com/img/slides/flexslider/4.jpg"} style={{ width: 900, height: 350 ,display: "block", margin: "auto"}}alt="Coding Beauty logo"></img></SwiperSlide>

      ...
    </Swiper>
        <HeroSection myData={data} />
        <Trusted />
      </>
   

   
  )
  }


// const Wrapper = styled.section`
// background-color:${({theme})=> theme.colors.bg};
// width:20rem;
// height:20rem;

// img{
 
//   display: "block";
//    margin: "auto";
// }
// `




export default Home;
