import React from 'react'

const Cart = () => {
  return (
    <div>
      <p>Cart Page</p>
    </div>
  )
}

export default Cart
