import React from 'react'
import Chat from './Chat';
import SignIn from './SignIn';
import firebase from 'firebase/compat/app';
import { useAuthState } from 'react-firebase-hooks/auth'

const auth = firebase.auth();
const Support = () => {
  const [user] = useAuthState(auth);
  return (
    <div className="App">
      <header>
        
        {/* <SignOut /> */}
      </header>

      <section>
        {user ? <Chat /> : <SignIn />}
      </section>

    </div>
  )
}

export default Support
