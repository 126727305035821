import React from 'react'
import {  toast } from 'react-toastify';
import styled from "styled-components";
import { useRef } from 'react';
import GoToTop from "../components/GoToTop"
import { BiChevronsRight } from "react-icons/bi";


import KM829 from "../../images1/KM829.PNG";
import KM869 from "../../images1/KM869.PNG";
import KM711 from "../../images1/KM711.PNG";
import KM525521 from "../../images1/KM525-521.PNG"
import KM859CF from "../../images1/KM859CF.PNG"
import KM515 from "../../images1/KM515.PNG"
import KM655656 from "../../images1/KM655_656.PNG"
import K5040 from "../../images1/5040.PNG"
import KM69_66 from "../../images1/KM69_66.PNG"
import KM887 from "../../images1/KM887.PNG"
import KM877 from "../../images1/KM877.PNG"
import KM878 from "../../images1/KM878.PNG"
import KM857 from "../../images1/KM857.PNG"
import KM257 from "../../images1/KM257.PNG"
import KM233 from "../../images1/KM233.PNG"
import KM839 from "../../images1/KM839.PNG"
// import KM857 from "../../images1/KM857.PNG"
// import K5040 from "../../images1/K5040.PNG"
import KM805S from "../../images1/KM805S.PNG"
import KM807S from "../../images1/KM807S.PNG"
import KM822EX from "../../images1/KM822EX.PNG"
import KM235 from "../../images1/KM235.PNG"
import KM5040T from "../../images1/KM5040T.PNG"
import KM239R from "../../images1/KM239R.PNG"
import KM789 from "../../images1/KM789.PNG"



// Professional multimeter
import M3500A from "../../images1/M3500A.PNG";
import DMM90 from "../../images1/DMM90.PNG";
import KM629 from "../../images1/KM629.PNG";
import KM6050 from "../../images1/KM6050.PNG";
import KM6040 from "../../images1/KM6040.PNG";
import KM6030 from "../../images1/KM6030.PNG";
import KM6060 from "../../images1/KM6060.PNG";
import MK405 from "../../images1/MK405.PNG";
import KMST505N from "../../images1/KMST505N.PNG";
import KM891 from "../../images1/KM891.PNG";
import KM60 from "../../images1/KM60.PNG";
import KM60T from "../../images1/KM60T.PNG";
import KM8000HVDM from "../../images1/KM8000.PNG";
import KM237R from "../../images1/KM237R.PNG";

// Professional multimeter

// Industrial multimeter
import KM8045 from "../../images1/KM8045.PNG";
import MK207 from "../../images1/MK207.PNG";
import L801 from "../../images1/L801.PNG";
import M603 from "../../images1/M603.PNG";
import M702 from "../../images1/M702.PNG";
import M108 from "../../images1/M108.PNG";

// Industrial multimeter

// ULclampmeter
import A2754 from "../../images1/A2754.PNG";
import KM181 from "../../images1/KM181.PNG";
import KM061 from "../../images1/KM061.PNG";
import KM135 from "../../images1/KM135.PNG";
import K2772 from "../../images1/K2772.PNG";
import KM2799 from "../../images1/KM2799.PNG";
import KM111M from "../../images1/KM111M.PNG";
import BR5810 from "../../images1/BR5810.PNG";
import KM307 from "../../images1/KM307.PNG";
import KM2777 from "../../images1/KM2777.PNG";
import KM072 from "../../images1/KM072.PNG";
import KM076 from "../../images1/KM076.PNG";
import KM078 from "../../images1/KM078.PNG";
import KM086 from "../../images1/KM086.PNG";
import KM088 from "../../images1/KM088.PNG";
import KM175D from "../../images1/KM175D.PNG";
import KM035 from "../../images1/KM035.PNG";
import KM033 from "../../images1/KM033.PNG";
import KM039F from "../../images1/KM039F.PNG";
import KM3001 from "../../images1/KM3001.PNG";


// UL clampmeter

// PROFESSIONAL CLAMPMETER
import KM2775 from "../../images1/KM2775.PNG";
import KM071 from "../../images1/KM071.PNG";
import KM2782 from "../../images1/KM2782.PNG";
import KM2783 from "../../images1/KM2783.PNG";
import K9999 from "../../images1/K9999.PNG";
import KM2007 from "../../images1/KM2007.PNG";
import K2718 from "../../images1/K2718.PNG";
import KM2720 from "../../images1/KM2720.PNG";
import CA300 from "../../images1/CA300.PNG";
import CA500 from "../../images1/CA500.PNG";
import CA1000 from "../../images1/CA1000.PNG";
import KM2780 from "../../images1/KM2780.PNG";
import CA3000 from "../../images1/CA3000.PNG";
import KM2006 from "../../images1/KM2006.PNG";
import KM2008 from "../../images1/KM2008.PNG";
import KM2008A from "../../images1/KM2008A.PNG";
import KM4000 from "../../images1/KM4000.PNG";
import KM4002 from "../../images1/KM4002.PNG";
import KMCCT3K from "../../images1/KMCCT3K.PNG";
import KM2779 from "../../images1/KM2779.PNG";


// PROFESSIONAL CLAMPMETER

// Industrialclampmeter
import K2781 from "../../images1/K2781.PNG";
import K2700 from "../../images1/K2700.PNG";
import K999 from "../../images1/K999.PNG";
import K2763 from "../../images1/K2763.PNG";
import A860 from "../../images1/A860.PNG";
import KM3060 from "../../images1/KM3060.PNG";
import KM3060T from "../../images1/KM3060T.PNG";
import KM2719 from "../../images1/KM2719.PNG";

// Industrialclampmeter

// Earth resistance meter
import KM1430 from "../../images1/KM1430.PNG";
import KM1440 from "../../images1/KM1440.PNG";
import KM1520 from "../../images1/KM1520.PNG";
import KM1620 from "../../images1/KM1620.PNG";
import KM1520A from "../../images1/KM1520A.PNG";
import KM4234ER from "../../images1/KM4234ER.PNG";
import KM1105ER from "../../images1/KM1105ER.PNG";
import KM2040 from "../../images1/KM2040.PNG";
import KM2030 from "../../images1/KM2030.PNG";
import KM4235R from "../../images1/KM4235R.PNG";
import KM1320 from "../../images1/KM1320.PNG";
import C2800 from "../../images1/C2800.PNG";
import KM1120 from "../../images1/KM1120.PNG";
import KM1690 from "../../images1/KM1690.PNG";
import KM1920 from "../../images1/KM1920.PNG";
import EX1820 from "../../images1/EX1820.PNG";
import KC2800 from "../../images1/KC2800.PNG";
import KM5208LP from "../../images1/KM5208LP.PNG";
import KM1720 from "../../images1/KM1720.PNG";


// Earth resistance meter

// Insulation resistance meter
import TMF1154 from "../../images1/TMF1154.PNG";
import KM01 from "../../images1/KM01.PNG";
import KM370 from "../../images1/KM370.PNG";
import KM2803IN from "../../images1/KM2803IN.PNG";
import KM2804IN from "../../images1/KM2804IN.PNG";
import KM6213AIN from "../../images1/KM6213AIN.PNG";
import KM2805 from "../../images1/KM2805.PNG";
import KM6305IN from "../../images1/KM6305IN.PNG";
import KM7015IN from "../../images1/KM7015IN.PNG";
import RCB31T from "../../images1/RCB31T.PNG";
import KM6310IN from "../../images1/KM6310IN.PNG";
import MF1152 from "../../images1/MF1152.PNG";
import KM7016IN from "../../images1/KM7016IN.PNG";
import KM5305IN from "../../images1/KM5305IN.PNG";
import KM5405 from "../../images1/KM5405.PNG";

// Insulation resistance meter

// High Voltage Measuring Instruments
import HD230 from "../../images1/HD230.PNG";
import HP275 from "../../images1/HP275.PNG";
import HD276 from "../../images1/HD276.PNG";
import SVD286 from "../../images1/SVD286.PNG";
import DISCHARGEROD from "../../images1/DISCHARGEROD.PNG";
import HVD213 from "../../images1/HVD213.PNG";
import PD28 from "../../images1/PD28.PNG";
import PC7K from "../../images1/PC7K.PNG";
import KM8000 from "../../images1/KM8000.PNG";
import HSR120 from "../../images1/HSR120.PNG";
import HP273 from "../../images1/HP273.PNG";
import HP278 from "../../images1/HP278.PNG";
import SVD287 from "../../images1/SVD287.PNG";
import SVD288 from "../../images1/SVD288.PNG";
import SVD388 from "../../images1/SVD388.PNG";
import HD285 from "../../images1/HD285.PNG";
import KMMPS50K from "../../images1/KMMPS50K.PNG";
import ALS1 from "../../images1/ALS1.PNG";
import HP277 from "../../images1/HP277.PNG";
import HP375 from "../../images1/HP375.PNG";
import KM302SVD from "../../images1/KM302SVD.PNG";
import HSR1243M from "../../images1/HSR1243M.PNG";
import HSR125T5M from "../../images1/HSR125T5M.PNG";
import HSR1313M from "../../images1/HSR1313M.PNG";
import HSR1325M from "../../images1/HSR1325M.PNG";
import KMRH11KV from "../../images1/KMRH11KV.PNG";
import KMCVD11KV from "../../images1/KMCVD11KV.PNG";
import HSR13512M from "../../images1/HSR13512M.PNG";


//  High Voltage Measuring Instruments

// Coating Thickness Guage
import KM8041 from "../../images1/KM8041.PNG";
import KM8042 from "../../images1/KM8042.PNG";
import KM118 from "../../images1/KM118.PNG";
// Coating Thickness Guage

// ELCB TESTER
import EL1812 from "../../images1/EL1812.PNG";
import EL1813 from "../../images1/EL1813.PNG";
import EL1811 from "../../images1/EL1811.PNG";
// ELCB TESTER

// ENVIRONMENTAL TESTING INSTRUMENTS
import KM36 from "../../images1/KM36.PNG";
import KM63 from "../../images1/KM63.PNG";
import KM192 from "../../images1/KM192.PNG";
import KM195 from "../../images1/KM195.PNG";
import KM600NH from "../../images1/KM600NH.PNG";
import KM601 from "../../images1/KM601.PNG";
import KM918  from "../../images1/KM918.PNG";
import KM918A from "../../images1/KM918A.PNG";
import KM919 from "../../images1/KM919.PNG";
import KM963G from "../../images1/KM963G.PNG";
import KM963 from "../../images1/KM963.PNG";
import KM3961 from "../../images1/KM3961.PNG";
import KM6185 from "../../images1/KM6185.PNG";
import KM8010 from "../../images1/KM8010.PNG";
import KM8040 from "../../images1/KM8040.PNG";
import KM8070 from "../../images1/KM8070.PNG";
// import KM6502 from "../../images1/KM6502.PNG";
import KMSPM11 from "../../images1/KMSPM11.PNG";
import KMTHS03 from "../../images1/KMTHS03.PNG";
import KMLUX200K from "../../images1/KMLUX200K.PNG";
import KM963P from "../../images1/KM963P.PNG";
import KM963G2 from "../../images1/KM963G2.PNG";
import KM917 from "../../images1/KM917.PNG";
import KM920 from "../../images1/KM920.PNG";
import KM908MK1 from "../../images1/KM908MK1.PNG";
import KM6502 from "../../images1/KM6502.PNG";
import IRL380 from "../../images1/IRL380.PNG";
import IRL650 from "../../images1/IRL650.PNG";
import IRL866 from "../../images1/IRL866.PNG";
import IRL1100 from "../../images1/IRL1100.PNG";
import IRL1600 from "../../images1/IRL1600.PNG";
import IRL900 from "../../images1/IRL900.PNG";
import KM68164 from "../../images1/KM68164.PNG";
import WIRE3TRANSMITTER from "../../images1/WIRE3TRANSMITTER.PNG";
import KM926 from "../../images1/KM926.PNG";
import KM928MK1 from "../../images1/KM928MK1.PNG";
// import KM929MK1 from "../../images1/KM929MK1.PNG";
import TM100 from "../../images1/TM100.PNG";
import KM8080 from "../../images1/KM8080.PNG";
import KM9122 from "../../images1/KM9122.PNG";
import KM8102 from "../../images1/KM8102.PNG";
import KM8122 from "../../images1/KM8122.PNG";
import KM8010MK1 from "../../images1/KM8010MK1.PNG";
import KM929MK1 from "../../images1/KM929MK1.PNG";
import KM8022MK1 from "../../images1/KM8022MK1.PNG";
import KM910MK1 from "../../images1/KM910MK1.PNG";
import KM8051MK1 from "../../images1/KM8051MK1.PNG";
import KM8911A from "../../images1/KM8911A.PNG";
import IRBT1 from "../../images1/IRBT1.PNG";
import IRL180 from "../../images1/IRL180.PNG";
import IRL380F from "../../images1/IRL380F.PNG";
import KM531 from "../../images1/KM531.PNG";
import KM532 from "../../images1/KM532.PNG";
import KM735 from "../../images1/KM735.PNG";
import KM6190 from "../../images1/KM6190.PNG";


// ENVIRONMENTAL TESTING INSTRUMENTS

// LABORATORY INSTRUMENTS
import KMOHM8500 from "../../images1/KMOHM8500.PNG";
import KM2010 from "../../images1/KM2010.PNG";
import KM202025 from "../../images1/KM202025.PNG";
import KM205030 from "../../images1/KM205030.PNG";
import KM2050305060 from "../../images1/KM2050305060.PNG";
import KM205060 from "../../images1/KM205060.PNG";
import KM2002 from "../../images1/KM2002.PNG";
// import KM3165 from "../../images1/KM3165.PNG";
import KM8045DMM from "../../images1/KM8045DMM.PNG";
import SHS1062 from "../../images1/SHS1062.PNG";
import KM3165 from "../../images1/KM3165.PNG";
import DCPOWERSUPPLY from "../../images1/DCPOWERSUPPLY.PNG";
import KM2001 from "../../images1/KM2001.PNG";
import KM2005 from "../../images1/KM2005.PNG";

// LABORATORY INSTRUMENTS
// DIGITAL ANEMOMETERS
import KM8022 from "../../images1/KM8022.PNG";
import KM909 from "../../images1/KM909.PNG";
import KM730 from "../../images1/KM730.PNG";
// DIGITAL ANEMOMETERS

// DIGITAL LUX METER
import KM8051 from "../../images1/KM8051.PNG";
import KMLUX99 from "../../images1/KMLUX99.PNG";
import KM203 from "../../images1/KM203.PNG";
// DIGITAL LUX METER

// INFRARED THREMOMETERS
import BX500 from "../../images1/BX500.PNG";
import IRL300 from "../../images1/IRL300.PNG";
import KM688 from "../../images1/KM688.PNG";
// INFRARED THREMOMETERS

// POWER MEASUREMENT CLAMPMETERS
import K2745 from "../../images1/K2745.PNG";
import KMPFRS904 from "../../images1/KMPFRS904.PNG";
import K2736 from "../../images1/K2736.PNG";
import KM2740 from "../../images1/KM2740.PNG";
import KM351 from "../../images1/KM351.PNG";
import KM357 from "../../images1/KM357.PNG";
import KM2710 from "../../images1/KM2710.PNG";
import K2760 from "../../images1/K2760.PNG";

// POWER MEASUREMENT CLAMPMETERS

// POWER MEASUREMENT AND CONTROL INSTRUMENTS
import KM7200A from "../../images1/KM7200A.PNG";
import KM7200B from "../../images1/KM7200B.PNG";
import KM7200C from "../../images1/KM7200C.PNG";
import KM8100A from "../../images1/KM8100A.PNG";
import KM8100B from "../../images1/KM8100B.PNG";
import KM8100C from "../../images1/KM8100C.PNG";
import KM6300 from "../../images1/KM6300.PNG";
import KMPM100 from "../../images1/KMPM100.PNG";
import KM9600 from "../../images1/KM9600.PNG";
import RKMEA101 from "../../images1/RKMEA101.PNG";
import K2709 from "../../images1/K2709.PNG";
import KM2100 from "../../images1/KM2100.PNG";
import KMPFRS04 from "../../images1/KMPFRS04.PNG";
import KM2300 from "../../images1/KM2300.PNG";


// POWER MEASUREMENT AND CONTROL INSTRUMENTS

// POWER FACTOR REGULATOR
import KMPFR906 from "../../images1/KMPFR906.PNG";
import KMPFRS908 from "../../images1/KMPFRS908.PNG";
// POWER FACTOR REGULATOR

//PROGRAMMABLE DIGITAL PANEL METERS
import DM3A from "../../images1/DM3A.PNG";
import DM3V from "../../images1/DM3V.PNG";
import DMAR from "../../images1/DMAR.PNG";
import DMD from "../../images1/DMD.PNG";
import DMDPW from "../../images1/DMDPW.PNG";
import DME from "../../images1/DME.PNG";
import DMF from "../../images1/DMF.PNG";
import DML from "../../images1/DML.PNG";
import DMP from "../../images1/DMP.PNG";
import DMPF from "../../images1/DMPF.PNG";
import DMU from "../../images1/DMU.PNG";
import DMV from "../../images1/DMV.PNG";
import DMVA from "../../images1/DMVA.PNG";
import DMW from "../../images1/DMW.PNG";
import DMWH from "../../images1/DMWH.PNG";
import MMV from "../../images1/MMV.PNG";
import MMA from "../../images1/MMA.PNG";
import MMD from "../../images1/MMD.PNG";
import MMF from "../../images1/MMF.PNG";
import MMG from "../../images1/MMG.PNG";
import MMPF from "../../images1/MMPF.PNG";
import MMQH from "../../images1/MMQH.PNG";
import MMR from "../../images1/MMR.PNG";
import MMS from "../../images1/MMS.PNG";
import MMT from "../../images1/MMT.PNG";
import MMW from "../../images1/MMW.PNG";
import MMWH from "../../images1/MMWH.PNG";
import MMWWH from "../../images1/MMWWH.PNG";
import DM3D from "../../images1/DM3D.PNG";
import DM3S from "../../images1/DM3S.PNG";
import DMB from "../../images1/DMB.PNG";


//PROGRAMMABLE DIGITAL PANEL METERS

// CALIBRATORS
import KM3600 from "../../images1/KM3600.PNG";
import KMCAL10 from "../../images1/KMCAL10.PNG";
import KMCAL18 from "../../images1/KMCAL18.PNG";
import KMCAL700 from "../../images1/KMCAL700.PNG";
import KMCAL710 from "../../images1/KMCAL710.PNG";
import KMCAL720 from "../../images1/KMCAL720.PNG";
import KMCAL901 from "../../images1/KMCAL901.PNG";
import KMCAL902 from "../../images1/KMCAL902.PNG";
import KMCAL903 from "../../images1/KMCAL903.PNG";
import KMCAL907 from "../../images1/KMCAL907.PNG";
import KMCAL906 from "../../images1/KMCAL906.PNG";
import KMCAL905 from "../../images1/KMCAL905.PNG";
import KMCAL904MK1 from "../../images1/KMCAL904MK1.PNG";
import KMCAL801 from "../../images1/KMCAL801.PNG";
import KMCAL802 from "../../images1/KMCAL802.PNG";
import KMCAL803 from "../../images1/KMCAL803.PNG";
import KMCAL804 from "../../images1/KMCAL804.PNG";
import KMCAL805 from "../../images1/KMCAL805.PNG";
import KM26 from "../../images1/KM26.PNG";

// CALIBRATORS

// GAS DETECTORS
import KM303 from "../../images1/KM303.PNG";
import KM310 from "../../images1/KM310.PNG";
import KM5430 from "../../images1/KM5430.PNG";
import KM5460 from "../../images1/KM5460.PNG";
import KM5470 from "../../images1/KM5470.PNG";
import KM5480 from "../../images1/KM5480.PNG";
import KM5490 from "../../images1/KM5490.PNG";
import KM315 from "../../images1/KM315.PNG";
import KM5420A from "../../images1/KM5420A.PNG";
import KMCO910 from "../../images1/KMCO910.PNG";
import E1000 from "../../images1/E1000.PNG";
import E4000 from "../../images1/E4000.PNG";
import E6000 from "../../images1/E6000.PNG";
import KM5491 from "../../images1/KM5491.PNG";
import KMGD04 from "../../images1/KMGD04.PNG";
import KM840LEL from "../../images1/KM840LEL.PNG";
import KM840O2 from "../../images1/KM840O2.PNG";
import KM7460 from "../../images1/KM7460.PNG";
import KM313 from "../../images1/KM313.PNG";


//GAS DETECTORS

// RECORDERS ANALYSER
import RECORDERSCATALOG from "../../images1/RECORDERSCATALOG.PNG";
import RECORDERS from "../../images1/RECORDERS.PNG";
import SYSTEMANALYSER from "../../images1/SYSTEMANALYSER.PNG";
import ENERGYANALYSER from "../../images1/ENERGYANALYSER.PNG";

// RECORDERS ANALYSER


// BANKING SOLUTIONS
import KCMPLUSDT from "../../images1/KCMPLUSDT.PNG";
import KM9000 from "../../images1/KM9000.PNG";
import KM9010 from "../../images1/KM9010.PNG";
import KM9012 from "../../images1/KM9012.PNG";
import K9018 from "../../images1/K9018.PNG";
import K9027 from "../../images1/K9027.PNG";
import KM9045 from "../../images1/KM9045.PNG";
import KM9011A from "../../images1/KM9011.PNG";
import KM9017 from "../../images1/KM9017.PNG";

// BANKING SOLUTIONS


// lcr meter

import M306 from "../../images1/M306.PNG";
import KM333 from "../../images1/KM333.PNG";
import KM503 from "../../images1/KM503.PNG";
import KM520B from "../../images1/KM520B.PNG"
import KM954MK2 from "../../images1/KM954MK2.PNG"
import LCR459 from "../../images1/LCR459.PNG"
// LCR METER

// SINGLE PHASE

import KV11 from "../../images1/KV11.PNG";
import KV66 from "../../images1/KV66.PNG";
import KV33 from "../../images1/KV33.PNG";
import KV132 from "../../images1/KV132.PNG"
import KV11ROD from "../../images1/KV11ROD.PNG"
import KV220 from "../../images1/KV220.PNG"
// SINGLE PHASE

// THREE PHASE

import KV11THREE from "../../images1/KV11THREE.PNG";
import KV33THREE from "../../images1/KV33THREE.PNG";
import KV66THREE from "../../images1/KV66THREE.PNG";
import KV132THREE from "../../images1/KV132THREE.PNG"
import KV220THREE from "../../images1/KV220THREE.PNG"
import KV400THREE from "../../images1/KV400THREE.PNG"
// THREE PHASE
// HIGHLY INSULATED FRP TELESCOPIC EARTH / DISCHARGE ROD

import KMRH33KV from "../../images1/KMRH33KV.PNG";
import KMHKDR33KV from "../../images1/KMHKDR33KV.PNG";
import KMHKDR66KV from "../../images1/KMHKDR66KV.PNG";
import KMHKDR132KV from "../../images1/KMHKDR132KV.PNG"
import KMHKDR220KV from "../../images1/KMHKDR220KV.PNG"
import KMHKDR220KV2 from "../../images1/KMHKDR220KV2.PNG"
// HIGHLY INSULATED FRP TELESCOPIC EARTH / DISCHARGE ROD

// POWER TRANSDUCERS

import TRMT from "../../images1/TRMT.PNG";
import TDV from "../../images1/TDV.PNG";
import TDA from "../../images1/TDA.PNG";
import TDW from "../../images1/TDW.PNG"
import TDWQ2 from "../../images1/TDWQ2.PNG"
import TDWH from "../../images1/TDWH.PNG"
import TDWWH from "../../images1/TDWWH.PNG"
import TDPF from "../../images1/TDPF.PNG"
import TDF from "../../images1/TDF.PNG"
import HC6610 from "../../images1/HC6610.PNG"

// POWER TRANSDUCERS

// CT’S WITH ANALOG OUTPUT
import TSCT from "../../images1/TSCT.PNG";
// CT’S WITH ANALOG OUTPUT

// CT’S WITH ANALOG OUTPUT
import TW2D from "../../images1/TW2D.PNG";
import TWD2 from "../../images1/TWD2.PNG";
import TWS from "../../images1/TWS.PNG";
import TWR from "../../images1/TWR.PNG";
import TWF from "../../images1/TWF.PNG";
import TWP from "../../images1/TWP.PNG";
import TW2S from "../../images1/TW2S.PNG";
import TWH from "../../images1/TWH.PNG";
import TWL from "../../images1/TWL.PNG";

// CT’S WITH ANALOG OUTPUT


// TRANSMITTERS WITH DISPLAY & ALARM OUTPUT
import TAD from "../../images1/TAD.PNG";
import TAP from "../../images1/TAP.PNG";
import TAR from "../../images1/TAR.PNG";
import TAS from "../../images1/TAS.PNG";
import TAT from "../../images1/TAT.PNG";
// TRANSMITTERS WITH DISPLAY & ALARM OUTPUT

// OSCILLOSCOPES
// ANALOG OSCILLOSCOPE
import KM5060 from "../../images1/KM5060.PNG";
// DIGITAL OSCILLOSCOPE
import SSA3032X from "../../images1/SSA3032X.PNG";
import KM2025CL from "../../images1/KM2025CL.PNG";
import KM2102CEX from "../../images1/KM2102CEX.PNG";

// POWER SUPPLIES
import KMPS302 from "../../images1/KMPS302.PNG";
import KMPS302D2 from "../../images1/KMPS302D2.PNG";
import KMPS3010AB from "../../images1/KMPS3010AB.PNG";
// POWER SUPPLIES

//WATERPROOF PEN TESTERS SUPPLIES
import MODEL6011 from "../../images1/MODEL6011.PNG";
import MODEL7000 from "../../images1/MODEL7000.PNG";
//WATERPROOF PEN TESTERS

// PORTABLE THERMAL IMAGING CAMERA
import TG301 from "../../images1/TG301.PNG";
import TG305 from "../../images1/TG305.PNG";

// PORTABLE THERMAL IMAGING CAMERA

// CABLE FAULT PRE-LOCATOR
import KMCFPL620 from "../../images1/KMCFPL620.PNG";
import KMCFPL612 from "../../images1/KMCFPL612.PNG";

// CABLE FAULT PRE-LOCATOR

// BATTERY QUALITY ANALYZER
import KM900 from "../../images1/KM900.PNG";
import KM930 from "../../images1/KM930.PNG";

// BATTERY QUALITY ANALYZER

// HIGH VOLTAGE TEST EQUIPMENTS
import KMHVBT3 from "../../images1/KMHVBT3.PNG";
import KMTRM1 from "../../images1/KMTRM1.PNG";
import KMRTS from "../../images1/KMRTS.PNG";
import KMCBT01 from "../../images1/KMCBT01.PNG";
import KM8500100 from "../../images1/KM8500100.PNG";
import KM85001 from "../../images1/KM85001.PNG";

// HIGH VOLTAGE TEST EQUIPMENTS

// MICRO /MILLI OHM METER 
import KMOHM8501 from "../../images1/KMOHM8501.PNG";
import KMOHM8502 from "../../images1/KMOHM8502.PNG";
import KM850012 from "../../images1/KM850012.PNG";
import MICROOHMETER114 from "../../images1/MICROOHMETER114.PNG";
// MICRO /MILLI OHM METER 

// WRIST TYPE HIGH VOLTAGE ALARM
import KMHVW289 from "../../images1/KMHVW289.PNG";
// WRIST TYPE HIGH VOLTAGE ALARM

// TORQUE WRENCH
import KMNG60 from "../../images1/KMNG60.PNG";
import KMNGM10 from "../../images1/KMNGM10.PNG";
import KMNGM30 from "../../images1/KMNGM30.PNG";
import KMNGM100 from "../../images1/KMNGM100.PNG";
import KMNGM200 from "../../images1/KMNGM200.PNG";

// TORQUE WRENCH

// DIGITAL CALIPER
import KMVC5200 from "../../images1/KMVC5200.PNG";
import KMVC5300 from "../../images1/KMVC5300.PNG";

// DIGITAL CALIPER



const Products = () => {
  const services = useRef(null)
  const blog = useRef(null)
  const contact = useRef(null)
  const Ulmultimeter = useRef(null)
  const LcrMeter = useRef(null)
  const Clampmeter = useRef(null)
  const ClampmeterUL = useRef(null)
  const ProfessionalClampmeter = useRef(null)
  const IndustrialClampmeter = useRef(null)
  const EarthResistanceTester = useRef(null)
  const InsulationResistanceTester = useRef(null)
  const HighVoltageMeasuringInstruments = useRef(null)
  const CoatingThicknessGuage = useRef(null)
  const  LCBTester = useRef(null)
  const  EnvironmentalTestingInstruments = useRef(null)
  const LaboratoryInstruments = useRef(null)
  const  DigitalLuxmeter = useRef(null)
  const  DigitalAnemometers = useRef(null)
const InfraredThermometers = useRef(null)
const PowerMeasurementClampmeters= useRef(null)
const PowerMeasurementAndControlInstruments = useRef(null)
const PowerFactorRegulator = useRef(null)
const ProgrammableDigitalPanelMeters = useRef(null)
const Calibrators = useRef(null)
const GasDetectors = useRef(null)
const RecordersAnalyser = useRef(null)
const CurrencyCounting = useRef(null)
const SinglePhase =  useRef(null)
const ThreePhase =  useRef(null)
const TelescopicEarth =  useRef(null)
const PowerTransducers = useRef(null)
const CtWithAnalogOutput = useRef(null)
const SignalTransmitters = useRef(null)
const TransmittersWithDisplayAndAlarmOutput = useRef(null)
const AnalogStorageOscilloscope = useRef(null)
const DigitalStorageOscilloscope =useRef(null)
const PowerSupplies = useRef(null)
const WaterproofPenTesters = useRef(null)
const PortableThermalImagingCamera = useRef(null)
const CableFaultPreLocator = useRef(null)
const BatteryQualityAnalyzer = useRef(null)
const HighVoltageTestEquipments = useRef(null)
const MicroMilliOhmMeter = useRef(null)
const WristTypeHighVoltageAlarm = useRef(null)
const TorqeuWrench = useRef(null)
const DigitalCaliper = useRef(null)

toast.info('CLICK ON THE NAMES TO GO TO THE IMAGES', {
  position: "top-left",
  autoClose: 10000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "dark",
  });
 
  setTimeout(() => {

    const interval = setInterval(() => {
      console.log('Interval triggered');
      toast.info('NOTE: CLICK ON THE IMAGES TO GET THE SPECIFICATION SHEET', {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
    }, 11000);
    
    setTimeout(()=>{
      clearInterval(interval);
    },45000)
  },10000)


const scrollToSection = (elementRef) => {
  window.scrollTo({
    top: elementRef.current.offsetTop,
    behavior:'smooth'
  });
}

  return (
    
    <Wrapper>
    <GoToTop />
     <div className='App'>
     

      <div className='hero'>
        <ul>
      <h1  style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(Ulmultimeter)} className='head'>DIGITAL MULTIMETERS</h1>
          <li style={{color: "blue"}} onClick={()=> scrollToSection(services)} className='link'>   HIGH SAFETY UL APPROVED DIGITAL MULTIMETERS</li>
          <li style={{color: "blue"}} onClick={()=> scrollToSection(blog)} className='link'>  PROFESSIONAL GRADE DIGITAL MULTIMETERS</li>
          <li style={{color: "blue"}} onClick={()=> scrollToSection(contact)} className='link'>  INDUSTRIAL GRADE DIGITAL MULTIMETERS</li>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(LcrMeter)} className='head'>LCR METERS</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(Clampmeter)} className='head'>DIGITAL CLAMPMETERS</h1>
          <li style={{color: "blue"}} onClick={()=> scrollToSection(ClampmeterUL)} className='link'>  HIGH SAFETY “UL” APPROVED DIGITAL CLAMPMETERS</li>
          <li style={{color: "blue"}} onClick={()=> scrollToSection(ProfessionalClampmeter)} className='link'>  PROFESSIONAL GRADE DIGITAL CLAMPMETERS</li>
          <li style={{color: "blue"}} onClick={()=> scrollToSection(contact)} className='link'>  INDUSTRIAL GRADE DIGITAL CLAMPMETERS</li>

          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(EarthResistanceTester)} className='head'>EARTH RESISTANCE TESTER</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(InsulationResistanceTester)} className='head'>INSULATION RESISTANCE TESTER</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(HighVoltageMeasuringInstruments)} className='head'>HIGH VOLTAGE MEASURING INSTRUMENTS</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(CoatingThicknessGuage)} className='head'>COATING THICKNESS GUAGE</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(LCBTester)} className='head'>ELCB TESTER</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(EnvironmentalTestingInstruments)} className='head'>ENVIRONMENTAL TESTING INSTRUMENTS</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(LaboratoryInstruments)} className='head'>LABORATORY INSTRUMENTS</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(DigitalAnemometers)} className='head'>DIGITAL ANEMOMETERS</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(DigitalLuxmeter)} className='head'>DIGITAL LUX METER</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(InfraredThermometers)} className='head'>INFRARED THERMOMETERS</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(PowerMeasurementClampmeters)} className='head'>POWER MEASUREMENT CLAMPMETERS</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(PowerMeasurementAndControlInstruments)} className='head'>POWER MEASUREMENT & CONTROL  INSTRUMENTS</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(PowerFactorRegulator)} className='head'>POWER FACTOR REGULATOR</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(ProgrammableDigitalPanelMeters)} className='head'>PROGRAMMABLE DIGITAL PANEL METERS</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(Calibrators)} className='head'>CALIBRATORS</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(GasDetectors)} className='head'>GAS DETECTORS</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(RecordersAnalyser)} className='head'>RECORDERS-ANALYSERS</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(CurrencyCounting)} className='head'>BANKING SOLUTIONS</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(SinglePhase)} className='head'>DISCHARGE 1 PHASE & 3 PHASE RODS</h1>
          <li style={{color: "blue"}} onClick={()=> scrollToSection(SinglePhase)} className='link'>   SINGLE PHASE</li>
          <li style={{color: "blue"}} onClick={()=> scrollToSection(ThreePhase)} className='link'>  THREE PHASE</li>
          <li style={{color: "blue"}} onClick={()=> scrollToSection(TelescopicEarth)} className='link'>  HIGHLY INSULATED FRP TELESCOPIC EARTH / DISCHARGE ROD</li>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(PowerTransducers)} className='head'>POWER TRANSDUCERS</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(CtWithAnalogOutput)} className='head'>CT’S WITH ANALOG OUTPUT</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(SignalTransmitters)} className='head'>SIGNAL TRANSMITTERS</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(TransmittersWithDisplayAndAlarmOutput)} className='head'>TRANSMITTERS WITH DISPLAY & ALARM OUTPUT</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(AnalogStorageOscilloscope)} className='head'>OSCILLOSCOPES</h1>
          <li  style={{color: "blue"}} onClick={()=> scrollToSection(AnalogStorageOscilloscope)} className='link'>  ANALOG STORAGE OSCILLOSCOPE</li>
          <li  style={{color: "blue"}} onClick={()=> scrollToSection(DigitalStorageOscilloscope)} className='link'>  DIGITAL STORAGE OSCILLOSCOPE</li>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(PowerSupplies)} className='head'>POWER SUPPLIES</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(WaterproofPenTesters)} className='head'>WATERPROOF PEN TESTERS</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(PortableThermalImagingCamera)} className='head'>PORTABLE THERMAL IMAGING CAMERA</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(CableFaultPreLocator)} className='head'>CABLE FAULT PRE-LOCATOR</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(BatteryQualityAnalyzer)} className='head'>BATTERY QUALITY ANALYZER</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(HighVoltageTestEquipments)} className='head'>HIGH VOLTAGE TEST EQUIPMENTS</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(MicroMilliOhmMeter)} className='head'>MICRO /MILLI OHM METER </h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(WristTypeHighVoltageAlarm)} className='head'>WRIST TYPE HIGH VOLTAGE ALARM </h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(TorqeuWrench)} className='head'>TORQUE WRENCH</h1>
          <h1 style={{fontSize:"2.3rem", color: "red"}} onClick={()=> scrollToSection(DigitalCaliper)} className='head'>DIGITAL CALIPER </h1>




        </ul>
        
      </div>
      {/* HIGH SAFETY UL APPROVED DIGITAL MULTIMETERS */}
      <div ref ={ Ulmultimeter} className='services'>
      <div ref ={ services} className='services'>
       <h3 style={{ color: "red"}}>DIGITAL MULTIMETERS</h3>
      
     <h3 style={{color: "red", paddingBottom: "5rem" }}>HIGH SAFETY UL APPROVED DIGITAL MULTIMETERS</h3>
        <div>
    
     {/* <p >• <BiChevronsRight/> TRMS Digital Insulation Multimeter with added VFD, capacitance & Temperature Function Model KM 887</p> */}
     {/* <ol style={{ listStyleType: 'upper-roman' }}>
                    <li><BiChevronsRight/> Goosberry</li>
                    <li>Bore</li>
                    <li>Peaches</li>
                </ol> */}

                <p style={{ paddingTop: "2rem" }}><BiChevronsRight/>Model: KM 887- TRMS Digital Insulation Multimeter with added VFD, capacitance & Temperature Function </p>
                <p ><BiChevronsRight />Model: KM 877- TRMS Digital Insulation Multimeter with added VFD, PI, DAR & PASS/FAIL Insulation compare Feature </p>
                <p ><BiChevronsRight/>Model: KM 878- TRMS Digital Insulation Multimeter with added VFD, capacitance, AC/DC Current Range & Temperature Function </p>
                <p ><BiChevronsRight/>Model: KM 869- 500,000 Counts Autoranging True Rms Digital Multimeter With VFD Feature & Pc Interface  </p>
                <p ><BiChevronsRight/>Model: KM 857- 5-4/5 Digits(500000 Counts)20 KHz TRMS Digital Multimeter </p>
                <p ><BiChevronsRight/>Model: KM 829- 10000 Counts Dual Display TRMS Digital Multimeter  </p>
                <p ><BiChevronsRight/>Model: KM 525- AC + DC True RMS Mobile Logger Digital Multimeter </p>
                <p ><BiChevronsRight/>Model: KM 257-  3-5/6 DIGIT 6000 Voltage Tester Counts Digital TRMS Multimeter With EF - Detection & Computer Interface </p>
                <p ><BiChevronsRight/>Model: KM 233- 3-5/6 Digit 6000 Counts Digital Multimeter With VFD, EF-detection  </p>
                <p ><BiChevronsRight/>Model: KM 237R- Digital Multimeter With VFD, Ef-detection 3 phase Rotation-r & 3phase Rotation-m </p>
                <p ><BiChevronsRight/>Model: KM 839- 3-5/6 Digit & 4½ Digit 20,000 Counts True RMS Digital Multimeter </p>
                <p ><BiChevronsRight/>Model: KM 859CF- 5-4/5 Digits(500000 Counts)100KHz TRMS Digital Multimeter </p>
                <p ><BiChevronsRight/>Model: 5040- 5000 Counts Digital Multimeter with Analog Bar Graph & RS-232 Computer Interface </p>
                <p ><BiChevronsRight/>Model: KM 69 / KM 66- Voltage Detector With RCD Load Test & EF Detection </p>
                <p ><BiChevronsRight/>Model: KM 805s-4000 Counts Autoranging Digital Multimeter  </p>
                <p ><BiChevronsRight/>Model: KM 807s- 4000 Counts Autoranging True RMS Digital Multimeter </p>
                <p ><BiChevronsRight/>Model: KM 822- EX Intrinsically Safe TRUE RMS Digital Multimeter With PC Interface </p>
                <p ><BiChevronsRight/>Model: KM 235- 3-5/6 Digits(6000 Counts) TRMS Digital Multimeter with VFD, EF-Detection </p>
                <p ><BiChevronsRight/>Model: 5040- T 5000 Counts Digital Multimeter with Analog Bar Graph & RS-232 Computer Interface </p>
                <p ><BiChevronsRight/>Model: KM 239R- 6,000 Counts True TRMS Digital Multimeter With VFD EF-Detetection </p>
                <p style={{ paddingBottom: "5rem" }} ><BiChevronsRight/>Model – KM 789 60,000 Counts AC & AC+DC TRMS Digital Multimeter with Non Contact EF-Detetection </p>


     <div className='container grid grid-three-column'>
     <div><a href="https://drive.google.com/file/d/1qG9QytoQWlmomJow8_ZB9sJX-9Rd7SN4/view?usp=drive_link" target="_blank" rel="noreferrer">
        {/* <GridListTile> */}
        <img src={KM829} className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
        {/* <GridListTileBar
            title='KM829'
            // subtitle={<span>by: GeeksforGeeks</span>}
            // actionIcon={
            //   <IconButton aria-label={`Best Place to learn`}>
            //     <InfoIcon />
            //   </IconButton>
            // }
          />
          </GridListTile> */}
      </a>
      <p>KM829</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1G7KDAydhelbcxMnEavugq2FpCGJV7e5R/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img className="hover" src={KM869} style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM869</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1Zh_U1OQUGFW2h_Kd4cY-KtltS7DZa42u/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img className="hover" src={KM711} style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM711</p>
      </div>

      <div>
      <a href="https://drive.google.com/file/d/1coPZFpXuOU7ERgA7XDgG29t_kXDOQ2lU/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM525521} className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM525-551</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1A4bttgaeFtogCwstTP4lKuPvpu9fB5Kq/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM859CF} className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM859 CF</p>
      </div>
      {/* <div>
      <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSbXdPdno4YWZrelk/view?resourcekey=0-3JHeq1tL2z8IaBOjMNCW7w" target="_blank" rel="noreferrer">
        <img src={KM515} className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM515</p>
      </div> */}
      <div>
      <a href="https://drive.google.com/file/d/1Dw7PYXGf-F639btPQx8XUZ9Wg7qS2k8w/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM655656} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM655_656</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1g2J2gwA-9kozTiy9G8lK5knoXz-5bsvS/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={K5040} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>5040</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1XXY2Cdt0Zw37tsvR2QmwqedORyh2LLei/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM69_66}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM69 - KM66</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20887.pdf" target="_blank" rel="noreferrer">
        <img src={KM887}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM887</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20877.pdf" target="_blank" rel="noreferrer">
        <img src={KM877}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM877</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20878.pdf" target="_blank" rel="noreferrer">
        <img src={KM878}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM878</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20857.pdf" target="_blank" rel="noreferrer">
        <img src={KM857}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM857</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20255%20&%20257.pdf" target="_blank" rel="noreferrer">
        <img src={KM257}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM257</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20233.pdf" target="_blank" rel="noreferrer">
        <img src={KM233}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM233</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20237R.pdf" target="_blank" rel="noreferrer">
        <img src={KM237R}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM237R</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20839.pdf" target="_blank" rel="noreferrer">
        <img src={KM839}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM839</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/5040.pdf" target="_blank" rel="noreferrer">
        <img src={K5040}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>5040</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20805s.pdf" target="_blank" rel="noreferrer">
        <img src={KM805S}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM805S</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20807s.pdf" target="_blank" rel="noreferrer">
        <img src={KM807S}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM805S</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20822EX.pdf" target="_blank" rel="noreferrer">
        <img src={KM822EX}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM805S</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20235.pdf" target="_blank" rel="noreferrer">
        <img src={KM235}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM235</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%205040-T.pdf" target="_blank" rel="noreferrer">
        <img src={KM5040T}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM5040-T</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20239R.pdf" target="_blank" rel="noreferrer">
        <img src={KM239R}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM239R</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20789.pdf" target="_blank" rel="noreferrer">
        <img src={KM789}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM789</p>
      </div>
      
      </div>
      </div>


      </div>
            </div>
{/* HIGH SAFETY UL APPROVED DIGITAL MULTIMETERS */}


      <div  ref ={ blog}  className='blog'>
        <h3 style={{color:"red"}} className='padder'  >PROFESSIONAL GRADE DIGITAL MULTIMETERS</h3>
      {/*  PROFESSIONAL GRADE DIGITAL MULTIMETERS*/}
      
      <p ><BiChevronsRight/>Model: M3500A-M3510A-M3511A </p>
      <p ><BiChevronsRight/>Model: KM 629 </p>
      <p ><BiChevronsRight/>Model: KM 6050 </p>
      <p ><BiChevronsRight/>Model: KM 6040CF </p>
      <p ><BiChevronsRight/>Model: KM 6060 </p>
      <p ><BiChevronsRight/>Model: 405MK-1- Digital Trms Multimeter  </p>
      <p ><BiChevronsRight/>Model: DMM 90- 4 Digit Digital Multimeter  </p>
      <p ><BiChevronsRight/>Model: KM 6030- 3 (2000 Counts) Digital Multimeter With Mechanical Locking Protection  </p>
      <p ><BiChevronsRight/>Model: KM-ST-505N - Analog Multimeter </p>
      <p ><BiChevronsRight/>Model: KM 891- 4-5/6 Digit 60,0000 counts Autoranging TRMS DMM With Datalogging & PC Interface  </p>
      <p ><BiChevronsRight/>Model: KM 60- Touch Pad Digital Multimeter  </p>
      <p ><BiChevronsRight/>Model: KM 60-T- TRMS Digital Multimeter  </p>
      <p style={{ paddingBottom: "5rem" }} ><BiChevronsRight/>Model: 8000 HVDM- True RMS High Voltage Digital Multimeter  </p>
   



    <div className='container grid grid-three-column'>
        <div className='short'>
    <a href="https://drive.google.com/file/d/1TQdOgqkR8FlC2AmHXRSoFrDYQ2Zah6in/view?usp=drive_link" target="_blank" rel="noreferrer"> 
    <img src={M3500A}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
    </a>
    <p>M3500A-M3510A-M3511A</p>
        </div>
      <div>
      <a href="https://drive.google.com/file/d/1BxOinDS27wyor81wjnYKEzlvsiV9p4QR/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={DMM90}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>DMM90</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1TJ2SDqOoHgzk-VmedxDv_z87tLJxLRSy/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM629}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM629</p>
      </div>

      <div>
      <a href="https://drive.google.com/file/d/1nc-ZSAj35WrIFEppyGvoeorWx5jGFh4g/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM6050} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM6050</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1N6rNlJxtjvlz_IgFs0sIGewWKu0qlYGG/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM6040} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM6040 CF</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1EnUmKfK42rDfaIkw4Z27bwpB46ukX-9t/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM6030} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM6030</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1LQy_c8FxDOVM33cUfXnpJfdAgKAmNWFL/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM6060} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM6060</p>
      </div>
      
      <div>
      <a href="https://kusamelectrical.com/pdf/405MK-1.pdf" target="_blank" rel="noreferrer">
        <img src={MK405} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM405MK-1</p>
      </div>
      
      <div>
      <a href="https://kusamelectrical.com/pdf/KM-ST-505N.pdf" target="_blank" rel="noreferrer">
        <img src={KMST505N} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM-ST-505N</p>
      </div>
      
      <div>
      <a href="https://kusamelectrical.com/pdf/KM-891.pdf" target="_blank" rel="noreferrer">
        <img src={KM891} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM891</p>
      </div>
      <div>
      
      <a href="https://kusamelectrical.com/pdf/KM-60.pdf" target="_blank" rel="noreferrer">
        <img src={KM60} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM 60</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%2060-T.pdf" target="_blank" rel="noreferrer">
        <img src={KM60T} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM60-T</p>
      </div>
   
     
      
     
      
</div>
</div>
      
      
      
      {/*  PROFESSIONAL GRADE DIGITAL MULTIMETERS*/}
      
      
      {/*  INDUSTRIAL GRADE DIGITAL MULTIMETERS*/}
      <div>
      <div ref ={ contact}  className='contact'>
        <h3 style={{ color:"red", paddingBottom: "5rem" }}>INDUSTRIAL GRADE DIGITAL MULTIMETERS</h3>


<p ><BiChevronsRight/>Model : KM 8045 </p>
<p ><BiChevronsRight/>Model : 801-L - 3 ¾ Digits (4000 Counts) Digital Multimeter </p>
<p ><BiChevronsRight/> Model : 207 MK-1 (T) - 3 ¾ Digits (4000 Counts) Auto Ranging Digital Multimeter </p>
<p ><BiChevronsRight/> Model : 603 - 3 ½ Digits (2000 Counts) Digital Multimeter </p>
<p ><BiChevronsRight/>Model : 702 - 3 ½ Digits (2000 Counts) Digital Multimeter  </p>
<p ><BiChevronsRight/> Model : 108 - 3 ½ Digits (2000 Counts) Digital Multimeter </p>
<p style={{ paddingBottom: "5rem" }} ><BiChevronsRight/>Model: Km 8000Hvdm - True Rms High Voltage Digital Multimeter  </p>


        <div className='container grid grid-three-column'>
    <div><a href="https://drive.google.com/file/d/1kzabNM35-Ygn8c5XOoVueiommxPYEqeK/view?usp=drive_link" target="_blank" rel="noreferrer">
      
        <img src={KM8045}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
       
      </a>
      <p>KM8045</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1FsdgsFt-DC-IgUonPYuythWsm2HmSB8G/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={MK207}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>207 MK 1</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1J4OM96rWksOOTSsCqMR0Pa6obuyKa_Wz/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={L801}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>801-L</p>
      </div>

      <div>
      <a href="https://drive.google.com/file/d/19w0BC-YCmQ7JfVQYadex5FAmfqg0gRIk/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={M603}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>603</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1hTLVXoyF4TdW2B1D0soWAtWn2s_Pk3Jh/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={M702} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>702</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1NIDvZePsTKlTf71czEwTANbMkTsuJY9_/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={M108}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>108</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM-8000HVDM.pdf" target="_blank" rel="noreferrer">
        <img src={KM8000HVDM}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM-8000HVDM</p>
      </div>
    
    
    
</div>
      </div>
    </div>
    {/* <div ref ={ LCRMeter} className='lcrmeter'>
    <ProductsLCR/>
    </div> */}
         
    
    <div ref ={ LcrMeter} className='lcrmeter'>
    <h3 style={{color:"red"}}>LCR METERS</h3>
<p style={{ paddingTop: "2rem" }} ><BiChevronsRight/> Model: 306</p>
<p ><BiChevronsRight/> Model: KM333 </p>
<p ><BiChevronsRight/>Model: KM503</p>
<p ><BiChevronsRight/> Model: KM502B</p>
<p ><BiChevronsRight/> Model: Km 954Mk-II- 3 Digit 1999 Counts Large Display Digital Lcr Meter</p>
<p ><BiChevronsRight/>Model: LCR 459 - Dual Display Autoranging LCR Meter To Measure L, C, R, Q, D </p>
<p ><BiChevronsRight/> Model: 306 - LCR Meter With Multimeter Functions</p>
<p ><BiChevronsRight/>Model: BR 5810 - LCR Meter </p>
<p ><BiChevronsRight/>Model: 333 - 3 Digits (2000 Counts) Digital Capacitance Multimeter  </p>
<p style={{ paddingBottom: "5rem" }}><BiChevronsRight/> Model: KM 307 - 3¾ Digit 4000 Counts Autoranging DMM + LCR METER</p>

         
          <div className='container grid grid-three-column'>
          <div><a href="https://drive.google.com/file/d/1MN3vYmOnziOXpPAiN4kpZS-cTPJcv71e/view?usp=drive_link" target="_blank" rel="noreferrer"> 
              <img src={M306} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
            </a>
            <p>306</p>
            </div>
            <div>
            <a href="https://drive.google.com/file/d/1H6co17K-ENG3uZ1HYWHljn4A5hzx2wR0/view?usp=drive_link" target="_blank" rel="noreferrer">
              <img src={KM333} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
            </a>
            <p>KM333</p>
            </div>
            <div>
            <a href="https://drive.google.com/file/d/1lwpbzKmzzIcxkZFbRf447T_Xtl_QBG7L/view?usp=drive_link" target="_blank" rel="noreferrer">
              <img src={KM503}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
            </a>
            <p>KM503</p>
            </div>
      
            <div>
            <a href="https://drive.google.com/file/d/1eGvA8WnDq0k06LaTduuyg7mP1tUvEHIX/view?usp=drive_link" target="_blank" rel="noreferrer">
              <img src={KM520B} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
            </a>
            <p>KM520B</p>
            </div>
            <div>
            <a href="https://drive.google.com/file/d/13NmTkjVpuWyMVcA758nn3ImaHEkzUMay/view?usp=drive_link" target="_blank" rel="noreferrer">
              <img src={KM954MK2}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
            </a>
            <p>KM954MK-II</p>
            </div>
            <div>
            <a href="https://drive.google.com/file/d/1hEqRBL7GiiB2IR1uktItuA0QYLnn1qXI/view?usp=drive_link" target="_blank" rel="noreferrer">
              <img src={LCR459}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
            </a>
            <p>LCR459</p>
            </div>
           
            <div>
      <a href="https://kusamelectrical.com/pdf/BR%205810.pdf" target="_blank" rel="noreferrer">
        <img src={BR5810}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>BR5810</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20307.pdf" target="_blank" rel="noreferrer">
        <img src={KM307}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM307</p>
      </div>
          
           
            
      </div>
      </div>
      
        
      


    {/* Clampmeter */}
{/* HIGH SAFETY “UL” APPROVED DIGITAL CLAMPMETERS */}
      <div ref ={ ClampmeterUL} className='servicesclampmeter'>
      <h3 style={{color:"red"}}>DIGITAL CLAMPMETERS</h3>
      <h3 style={{color:"red"}}>HIGH SAFETY “UL” APPROVED DIGITAL CLAMPMETERS</h3>
        <div>
    
<p style={{ paddingTop: "2rem" }} ><BiChevronsRight/> Model: 181</p>
<p ><BiChevronsRight/> Model: 135</p>
<p ><BiChevronsRight/> Model: KM 2777 AC / DC TRMS Clamp-On Multimeter with VFD, EF-Detection & PC Interface </p>
<p ><BiChevronsRight/> Model: KM 072 - 600A AC True RMS Digital Clampmeter With VFD, EF-detection, Amptip Function For Low Current Measurement</p>
<p ><BiChevronsRight/> Model: KM 076 - 600A AC True RMS Digital Clampmeter Model KM 076</p>
<p ><BiChevronsRight/> Model: KM 078 - AC+DC TRUE RMS True RMS Digital Clampmeter With VFD Model KM 078</p>
<p ><BiChevronsRight/> Model: KM 086 - 1000A AC True RMS Digital Clampmeter </p>
<p ><BiChevronsRight/> Model: KM 088 - 1000A AC/DC True RMS Digital Clampmeter </p>
<p ><BiChevronsRight/> Model: KM 175D - AC True RMS Digital Clampmeter </p>
<p ><BiChevronsRight/> Model: 2754A / 2754A-T - 1000 A DC / 800 A AC AVG / TRMS Clampmeter </p>
<p ><BiChevronsRight/> Model: KM 061 / KM 062 - 400A DC/AC Clamp-On Meter </p>
<p ><BiChevronsRight/> Model: KM 111M / KM 112M - 3 DIGITS Digital Clampmeter </p>
<p ><BiChevronsRight/> Model: 2772 - 2000 A AC TRMS Clampmeter </p>
<p ><BiChevronsRight/> Model: 2799 - Dual Display Clampmeter </p>
<p ><BiChevronsRight/> Model: KM 035 - AC/DC True RMS Digital Clampmeter with EF-Detection, AMPTip Function for Low Current Measurement </p>
<p ><BiChevronsRight/> Model: KM 033 - AC True RMS Digital Clampmeter with EF-Detection, AMPTip Function for Low Current Measurement </p>
<p ><BiChevronsRight/> Model: KM 039F - 3-5/6 digits 6000 counts TRMS Digital Fork Clampmeter </p>
<p  style={{ paddingBottom: "5rem" }}><BiChevronsRight/> Model: KM 3001 - AC / DC TRMS Clamp-On Multimeter with VFD, EF-Detection, PC Interface For Solar Application </p>




    <div className='container grid grid-three-column'>
    <div><a href="https://drive.google.com/file/d/1YOfKKTQS6nk_wMJg_XiQzoyKNmiTeY79/view?usp=drive_link" target="_blank" rel="noreferrer">
       
        <img src={A2754}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>2754A/2754A-T</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/16GraQNi4QD6Hsij9vw965-12o3L-zfbW/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM181}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM181</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1bgUgGvLfmvdwtBya-E8ngVuGvCidH_OE/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM061} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM061/KM062</p>
      </div>

      {/* <div>
      <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSYWZkalRNcXJpcms/view?resourcekey=0-rrKu6YYtI5vwP2dtS5rvnQ" target="_blank" rel="noreferrer">
        <img src={KM135} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM135</p>
      </div> */}
      <div>
      <a href="https://drive.google.com/file/d/1yppjdfVqlITrUSCmxEQ-KcwgvMlpwATU/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={K2772}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>2772</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1ZQKW4CZtzeHwBjaFBKxxytqmbWVjNyUl/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM2799}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>2799</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/128YlktOCjTMyWWn8GGEiLtSkRrAeGa0J/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM111M}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM111M/ KM112M</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1lkWeIbjBR-ImXSoD455dajEJ56mlW3O_/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM2777}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2777</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20072.pdf" target="_blank" rel="noreferrer">
        <img src={KM072}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM072</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20076.pdf" target="_blank" rel="noreferrer">
        <img src={KM076}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM076</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20078.pdf" target="_blank" rel="noreferrer">
        <img src={KM078}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM078</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20086.pdf" target="_blank" rel="noreferrer">
        <img src={KM086}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM086</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20088.pdf" target="_blank" rel="noreferrer">
        <img src={KM088}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM088</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20175D.pdf" target="_blank" rel="noreferrer">
        <img src={KM175D}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM175D</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20035.pdf" target="_blank" rel="noreferrer">
        <img src={KM035}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM035</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20033.pdf" target="_blank" rel="noreferrer">
        <img src={KM033}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM033</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20039F.pdf" target="_blank" rel="noreferrer">
        <img src={KM039F}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM039F</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%203001%20for%20solar.pdf" target="_blank" rel="noreferrer">
        <img src={KM3001}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM3001</p>
      </div>
      
</div>
</div>
      </div>
    {/* HIGH SAFETY “UL” APPROVED DIGITAL CLAMPMETERS */}

   {/*-------- Professional Clampmeter----------- */}
     
  
      <div ref ={ ProfessionalClampmeter} className='professionalclampmeter'>
      
      <h3 style={{color:"red"}}>PROFESSIONAL GRADE DIGITAL CLAMPMETERS</h3>
        <div>
    
<p style={{paddingTop: "2rem"}} ><BiChevronsRight  /> Model: KM2782/KM2782T</p>
<p ><BiChevronsRight /> Model:KM2783 </p>
<p ><BiChevronsRight /> Model: 2718 </p>
<p ><BiChevronsRight /> Model: CA300</p>
<p ><BiChevronsRight /> Model: 9999 - 1000 A AC / DC Clampmeter With Inrush Current Measurement </p>
<p ><BiChevronsRight /> Model: KM 2775 - 2000 A AC / DC Clampmeter </p>
<p ><BiChevronsRight /> Model: KM 071 - Digital Process Clampmeter </p>
<p ><BiChevronsRight /> Model: KM 2725 / KM 2720 - 40 A / 200A AC / DC Clampmeter  </p>
<p ><BiChevronsRight /> Model: KM 2780 - 1000A AC Digital Clampmeter </p>
<p ><BiChevronsRight /> Model: CA 500 - 500A AC/DC Current Clamp Adaptor </p>
<p ><BiChevronsRight /> Model: CA 1000/CA 2000 - 1000 A / 2000 A AC / DC Clamp Adaptor </p>
<p ><BiChevronsRight /> Model: CA 3000 / CA 3000 - D Flexible Current Probe / Meter </p>
<p ><BiChevronsRight /> Model: KM 2006 - AC DC TRMS Leakage Clampmeter </p>
<p ><BiChevronsRight /> Model: KM 2007 - 3 ¾ Digit AC Leakage Digital Clampmeter With Harmonics Low Pass Filter Function </p>
<p ><BiChevronsRight /> Model: KM 2008 - Leakage Current Clampmeter With Data Logging Function & PC Interface </p>
<p ><BiChevronsRight /> Model: 2008 - A Leakage Current Clampmeter KM </p>
<p ><BiChevronsRight /> Model: KM 4000 - 1000A AC True RMS Digital Clamp Meter </p>
<p ><BiChevronsRight /> Model: KM 4002 - 1000A AC/DC True RMS Digital Clamp Meter </p>
<p ><BiChevronsRight /> Model: KM CCT3K -  Flex Clamp Sensor upto 3000A </p>
<p ><BiChevronsRight style= {{paddingBottom:"5rem"}} /> Model: KM 2779 - 2500A Digital TRMS Clampmeter  </p>



    <div className='container grid grid-three-column'>
    <div><a href="https://drive.google.com/file/d/1TJqRESK8-lNo8iaHB6loek5cFEQT8x-z/view?usp=drive_link" target="_blank" rel="noreferrer">
       
        <img src={KM2775} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM2775</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1YWHEdNE75g_oJiP4fhtGKw3MrcDKBs_j/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM071}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM071</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1q_k2SYQ1S770RcBoSSvrIlDtMP2fakvR/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM2782}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2782/KM2782T</p>
      </div>

      <div>
      <a href="https://drive.google.com/file/d/1ABvhhTU5FflyDNYsME5iLxlSBEscYwTl/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM2783} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2783</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1aMHNYyi-1-ZwIozpa0l0_9SVWcSj4jsp/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={K9999}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>9999</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1XciJFUUVeZ_2ed0_hyGGn6SMzHf-ud4O/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM2007} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2007</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1PU1P6_NbWpJNtyOb2SVzxN_RmogEeAT9/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={K2718}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>2718</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/18wixx5XspO9n6zSEQQ-qs4_KWprb8WCQ/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM2720}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2720/KM2725</p>
      </div>
      {/* <div>
      <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSZ3VZV3RPTWItRzQ/view?resourcekey=0-0RmK_h6XJSUPSlShqW5GEg" target="_blank" rel="noreferrer">
        <img src={CA300} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>CA300</p>
      </div> */}
      <div>
      <a href="https://drive.google.com/file/d/1zbC10AS7ywG1C9QgYk_PFYJcrWXgCxxt/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={CA500} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>CA500</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1_x8_ndoA1lfDfIia_7ErFQhOeguktUnN/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={CA1000} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>CA-1000/2000</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%202780.pdf" target="_blank" rel="noreferrer">
        <img src={KM2780} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2780</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/CA%203000%20-%20CA%203000D.pdf" target="_blank" rel="noreferrer">
        <img src={CA3000} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>CA3000/CA3000D</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%202006%20Leakage%20clamp%20meter.pdf" target="_blank" rel="noreferrer">
        <img src={KM2006} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2006</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%202008%20revise%20march%2015.pdf" target="_blank" rel="noreferrer">
        <img src={KM2008} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2008</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%202008A.pdf" target="_blank" rel="noreferrer">
        <img src={KM2008A} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2008A</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%204000.pdf" target="_blank" rel="noreferrer">
        <img src={KM4000} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM4000</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%204002.pdf" target="_blank" rel="noreferrer">
        <img src={KM4002} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM4002</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM-CCT3K.pdf" target="_blank" rel="noreferrer">
        <img src={KMCCT3K} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM-CCT3K</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%202779.pdf" target="_blank" rel="noreferrer">
        <img src={KM2779} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2779</p>
      </div>
            
</div>
</div>

</div>
     
      

     {/* -------------Professional Clampmeter-------------- */}

      {/* -------------INDUSTRIAL Clampmeter-------------- */}
      
   {/* todo */}
      <div ref ={IndustrialClampmeter} className='industrialclampmeter'>
      
      <h3 style={{color:"red"}}>INDUSTRIAL GRADE DIGITAL CLAMPMETERS</h3>
        <div>
    
<p  style= {{paddingTop:"2rem"}}><BiChevronsRight /> MODEL:999 </p>
<p ><BiChevronsRight /> MODEL:2763  </p>
<p ><BiChevronsRight />  MODEL:860A </p>
<p ><BiChevronsRight /> Model : 2700 / 2790 - 300 A / 600 A AC Clampmeter  </p>
<p ><BiChevronsRight /> Model: KM 3060 - 1000A AC Digital Clampmeter  </p>
<p ><BiChevronsRight /> Model: 2781 / 2781 - T -  1000 A AC / DC AVG / TRMS Clampmeter  </p>
<p ><BiChevronsRight /> Model: KM 3060-T - 1000A AC Digital Clampmeter  </p>
<p  style={{ paddingBottom: "5rem"}}><BiChevronsRight /> Model: Km 2719-  3¾ Digits Ac Trms Digital Clampmeter  </p>




    <div className='container grid grid-three-column'>
    <div><a href="https://drive.google.com/file/d/1JwxnxTjz9DjW415mFQndxK1MbOtdMnJw/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={K2781} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>K2781/ km2781-T</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1g3xr23AEeoNoGTXD2NoQ0MbO1xHY7jKp/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={K2700} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>2700/2790</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1toyaK1D-xIbYZXV7338MKdNKIIEAHQX2/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={K999}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>999</p>
      </div>

      <div>
      <a href="https://drive.google.com/file/d/1WIVCRIuyPz3Hf6uXEdtBRSfRs6EX0Y82/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={K2763}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>2763</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1E21KPb7_osh4_YTHYvS5In7Z1AlcXJa7/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={A860}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>860A</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%203060.pdf" target="_blank" rel="noreferrer">
        <img src={KM3060}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM3060</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/manual/KM%203060-T%20Manual.pdf" target="_blank" rel="noreferrer">
        <img src={KM3060T}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM3060-T</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM-2719.pdf" target="_blank" rel="noreferrer">
        <img src={KM2719}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2719</p>
      </div>
      
            
</div>
</div>

</div>
    
       {/* -------------INDUSTRIAL Clampmeter-------------- */}

        {/* -------------EARTH RESISTANCE TESTER-------------- */}
      
   
      <div ref ={EarthResistanceTester} className='earthresistancetester'>
      
      <h3 style={{color:"red"}}>EARTH RESISTANCE TESTER</h3>
        <div>
        <p  style={{paddingTop:"2rem"}}><BiChevronsRight  /> MODEL : 1430  </p>
        <p ><BiChevronsRight /> MODEL :1440 </p>
        <p ><BiChevronsRight /> Model : KM 1520 -  3 Terminal Digital Earth Resistance Tester </p>
        <p ><BiChevronsRight /> Model: KM 1520A - 3 Terminal Digital Earth Resistance Tester  </p>
        <p ><BiChevronsRight />Model: KM 2030 - Digital Earth Resistance Tester  </p>
        <p ><BiChevronsRight />Model: KM 4234ER - Digital 4 Wire Earth Resistance Tester   </p>
        <p ><BiChevronsRight />Model: KM 1105ER - Analogue Earth  Resistance Tester </p>
        <p ><BiChevronsRight /> Model: KM 2040 - Terminal Earth Resistance And Soil Resistivity Tester   </p>
        <p ><BiChevronsRight />  Model:  KM 4235ER - 4 Wire Digital Earth Resistance And Soil Resistivity Tester </p>
        <p ><BiChevronsRight /> Model: KM 1320 - Earth Resistance Soil Resistivity Tester 2 / 3 / 4 Wire  </p>
        <p ><BiChevronsRight />Model: KM 2800C - Non-Contact Grounding Resistance Online Tester </p>
        <p ><BiChevronsRight />Model: KM 1120 / KM 1220 - Clamp on Type Earth  Resistance Tester / Current Leakage Tester  </p>
        <p ><BiChevronsRight /> Model: KM 1620 / KM 1720 - Clamp-On Type Earth Resistance Tester / Leakage Current Clampmeter  </p>
        <p ><BiChevronsRight /> Model: KM 1690 / KM 1790 - Clamp Ground Resistance Tester Model </p>
        <p ><BiChevronsRight /> Model: KM 1920 - Ground Pile Type Clamp Ground Resistance Tester  </p>
        <p ><BiChevronsRight />  Model: KM 1820EX - Flameproof Type Earth Resistance Tester</p>
        <p ><BiChevronsRight /> Model: 2800KC - Split Type Ground Resistance On-line Detector  </p>
        <p ><BiChevronsRight /> Model: KM-5208 LP - Loop Impedance Tester  </p>
        <p ><BiChevronsRight style={{paddingBottom:"5rem"}}/>Model: KM 1720+ - Clamp-on Type Digital Earth Resistance / Current Leakage Tester With RS232 PC Interface  </p>
 


    <div className='container grid grid-three-column'>
    {/* <div><a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSSjQ0Ql9PazQ1aTQ/view?resourcekey=0-uslLEWKPYMbK_YCu0o6LDw" target="_blank" rel="noreferrer">
       
        <img src={KM1430}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM1430</p>
      </div> */}
      {/* <div>
      <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSdHN0ZU55bDZUNTQ/view?resourcekey=0-OfJ3X7qnXe9jRRsqVYqqUA" target="_blank" rel="noreferrer">
        <img src={KM1440} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM1440</p>
      </div> */}
      <div>
      <a href="https://drive.google.com/file/d/11xHDOkpxNcyT5bpm0NPohEAK8BcumI0U/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM1520} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM1520</p>
      </div>

      {/* <div>
      <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSV1FMMnZqR1p4ZHc/view?resourcekey=0-Ko3R1hFW-RkOwZYXd7s9Aw" target="_blank" rel="noreferrer">
        <img src={KM1620}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM1620</p>
      </div> */}
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%201520A.pdf" target="_blank" rel="noreferrer">
        <img src={KM1520A}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM1520A</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%202030.pdf" target="_blank" rel="noreferrer">
        <img src={KM2030}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2030</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%204234ER.pdf" target="_blank" rel="noreferrer">
        <img src={KM4234ER}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM4234ER</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%201105ER.pdf" target="_blank" rel="noreferrer">
        <img src={KM1105ER}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM1105ER</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%202040.pdf" target="_blank" rel="noreferrer">
        <img src={KM2040}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2040</p>
      </div>
        <div>
      <a href="https://kusamelectrical.com/manual/Earth%20Resistance%20Testers/KM%204235ER.pdf" target="_blank" rel="noreferrer">
        <img src={KM4235R}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM4235R</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%201320.pdf" target="_blank" rel="noreferrer">
        <img src={KM1320}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM1320</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/2800C.pdf" target="_blank" rel="noreferrer">
        <img src={C2800}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>2800C</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%201120%20-%20KM%201220.pdf" target="_blank" rel="noreferrer">
        <img src={KM1120}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM1120/KM1220</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%201690%20&%20KM%201790.pdf" target="_blank" rel="noreferrer">
        <img src={KM1690}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM1690/KM1790</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%201920.pdf" target="_blank" rel="noreferrer">
        <img src={KM1920}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM1920</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%201820EX.pdf" target="_blank" rel="noreferrer">
        <img src={EX1820}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>1820EX</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/2800KC.pdf" target="_blank" rel="noreferrer">
        <img src={KC2800}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>2800KC</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%205208LP.pdf" target="_blank" rel="noreferrer">
        <img src={KM5208LP}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM5208LP</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%201720+.pdf" target="_blank" rel="noreferrer">
        <img src={KM1720}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM1720+</p>
      </div>
            
</div>
</div>

</div>
    
       {/* -------------EARTH RESISTANCE TESTER-------------- */}

         {/* -------------INSULATION RESISTANCE TESTER-------------- */}
      
   
      <div ref ={InsulationResistanceTester} className='insulationresistancetester'>
      
      <h3 style={{color:"red"}}>INSULATION RESISTANCE TESTER</h3>
        <div>
    
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight />Model: KM 6305IN - Digital 5KV High Voltage Insulation Tester with Rechargeable Battery </p>
        <p ><BiChevronsRight /> Model: KM 7015 IN - High voltage Insulation Resistance Tester </p>
        <p ><BiChevronsRight /> Model: KM 2803IN - 5 KV High Voltage  Digital Insulation resistance Tester </p>
        <p ><BiChevronsRight /> Model: KM 2804IN - 10 KV High Voltage Digital  Insulation resistance Tester</p>
        <p ><BiChevronsRight /> Model: KM 6213AIN - 10KV High Voltage Digital Insulation Resistance Tester  </p>
        <p ><BiChevronsRight />Model: KM 1152MF - Digital 1 KV Insulation Resistance Tester With Polarization Index (PI) & Dielectric Absorption Ratio (DAR) </p>
        <p ><BiChevronsRight />Model: KM 1154 TMF - Digital Multifunctions & Insulation Continuity-Voltage Tester  </p>
        <p ><BiChevronsRight />Model: KM 2805 MK -1(5KV, 1TΩ) - Digital Insulation Resistance Tester   </p>
        <p ><BiChevronsRight />Model: KM 370 / KM 379  - Digital Insulation Resistance Tester </p>
        <p ><BiChevronsRight /> Model:  RCB-3-1T  - Resistor Calibration Box</p>
        <p ><BiChevronsRight />  Model: KM 01 / 03 / 41 / 81 -  Analog Transistorised Insulation resistance Tester</p>
        <p ><BiChevronsRight /> Model: KM 6305AIN  - 5KV 10TW High Voltage Digital Insulation Tester with PI & DAR Function</p>
        <p ><BiChevronsRight /> Model: KM 6310AIN - 10KV 20TW High Voltage Digital Insulation Tester with PI & DAR Function </p>
        <p ><BiChevronsRight />Model: KM 7016IN - 15KV 30TW High Voltage Digital Insulation Resistance Tester with PI & DAR Function  </p>
        <p ><BiChevronsRight />Model: KM KM 5305IN / KM 5310IN / KM 5315IN - High Performance High Voltage Insulation Resistance Tester  </p>
        <p ><BiChevronsRight style={{paddingBottom:"5rem"}} />Model: KM KM 5405 / KM 5410 / KM 5415 - High Performance Digital Insulation Resistance Tester  </p>



    <div className='container grid grid-three-column'>
    <div><a href="https://drive.google.com/file/d/1PVx87BFhQTyowkEzUOv5cov4yLZW4gT3/view?usp=drive_link" target="_blank" rel="noreferrer">
       
        <img src={TMF1154} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>1154TMF</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1RB3uV2c3KgkzeCC8DFpZGKAnixOnPapK/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM01}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM01/03/41/81</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1Qth6HkO0yghQXCLU-qzINyIkgxjnlkI2/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM370}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM370</p>
      </div>

      <div>
      <a href="https://drive.google.com/file/d/15wTBlh558BPlBvEvszU7CZDWHdgVr3hN/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM2803IN} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM-2803IN</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1kFec07MsxbrEMrh5nAbx9nTmKJ695UkY/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM2804IN}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM-2804IN</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1BC2Cvqp1npmIser5lBjmHzANBVFWc7hF/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM2805}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2805</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/10oEIHtHH_oQvZG_EBQWaofbgWmKVeWAA/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM6213AIN}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM6213AIN</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1pli4HSEESpFcJ7zkrayqFE3XeXLQS6lX/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={MF1152} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>1152 MF</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%206305IN.pdf" target="_blank" rel="noreferrer">
        <img src={KM6305IN} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM6305IN</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%207015%20IN.pdf" target="_blank" rel="noreferrer">
        <img src={KM7015IN} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM7015IN</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/RCB-3-1T%20Resistor%20Calibration%20Box.pdf" target="_blank" rel="noreferrer">
        <img src={RCB31T} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>RCB31T</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%206310IN.pdf" target="_blank" rel="noreferrer">
        <img src={KM6310IN} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM6310IN</p>
      </div>
      <div>
      <a href="" target="_blank" rel="noreferrer">
        <img src={MF1152} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>1152 MF</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/high_voltage_digital_insulation_tester_KM_7016IN.htm#" target="_blank" rel="noreferrer">
        <img src={KM7016IN} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM7016IN</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%205305IN-5310IN-5315IN.pdf" target="_blank" rel="noreferrer">
        <img src={KM5305IN} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM5305IN/KM5310IN/KM5315IN</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%205405-KM%205410-KM%205415.pdf" target="_blank" rel="noreferrer">
        <img src={KM5405} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM5405/KM5410/KM5415</p>
      </div>
            
</div>
</div>

</div>
    
       {/* -------------INSULATION RESISTANCE TESTER-------------- */}



         {/* -------------HIGH VOLTAGE MEASURING INSTRUMENTS-------------- */}
      
   
         <div ref ={HighVoltageMeasuringInstruments} className='highvoltagmeasuringinstruments'>
      
      <h3 style={{color:"red"}}>HIGH VOLTAGE MEASURING INSTRUMENTS</h3>
        <div>
    
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> Model: 273 HP - Non - Contact Voltage Detector  </p>
        <p ><BiChevronsRight /> Model: 275 HP - (Upto 275KV) / 500 HP (Upto 500KV) High Voltage 275 KV / 500 KV Non-Contact Proximity detector </p>
        <p ><BiChevronsRight /> Model: 278 HP - Non - Contact High Voltage Detector  </p>
        <p ><BiChevronsRight /> Model: HSR - 120 / 120A / 120B / 120C / 121 / 122  Hot Sticks</p>
        <p ><BiChevronsRight /> Model: 213 HVD - Capacitive High Voltage Detector (Contact)  </p>
        <p ><BiChevronsRight /> Model: 286 SVD - 11.4KV Personal Safety High Voltage Detector </p>
        <p ><BiChevronsRight /> Model: 287 SVD -  Personal Safety H. V. Detector </p>
        <p ><BiChevronsRight /> Model: 288 SVD - High Voltage Proximity Detector </p>
        <p ><BiChevronsRight /> Model: 388 SVD - Personal Safety H. V. Detector </p>
        <p ><BiChevronsRight /> Model: 230HD - 36.5Kv / 290HD - 81.5Kv High Voltage Detector   </p>
        <p ><BiChevronsRight /> Model: 276HD - 24KV AC High Voltage Detector </p>
        <p ><BiChevronsRight /> Model: 285 HD - Non - High Voltage Detector  </p>
        <p ><BiChevronsRight /> Model: PD-28 -  Multi - High Voltage Probe</p>
        <p ><BiChevronsRight /> Model: KM-MPS-50K Multi - Purpose Digital High Voltage Phasing Meter </p>
        <p ><BiChevronsRight /> Model: PC7K / PC11K / PC22K / PC33K / PC44K High Voltage Multifunction Phasing Sticks - PC Series </p>
        <p ><BiChevronsRight /> Model:  ALS 1 - AC Line Separator </p>
        <p ><BiChevronsRight /> Model: 277HP - Non-Contact High Voltage Detector (50V ~ 132KV)</p>
        <p ><BiChevronsRight /> Model: 375HP  - Non-Contact High Voltage Detector (240V ~ 275KV) </p>
        <p ><BiChevronsRight /> Model: KM 302 - SVD Safety Voltage Detector (100V AC ~ 50KV AC) </p>
        <p ><BiChevronsRight /> Model: HSR-124(T)-3M - Triangle Hot Stick </p>
        <p ><BiChevronsRight /> Model: HSR-125(T)- 5M - Triangle Hot Stick </p>
        <p ><BiChevronsRight /> Model: HSR-131-3M - Round Hot Stick </p>
        <p ><BiChevronsRight /> Model: HSR-132-5M - Round Hot Stick </p>
        <p ><BiChevronsRight /> Model: HSR-135-12M - Round Hot Stick </p>
        <p ><BiChevronsRight /> Model: KM-RH-11KV / KM-RH-33KV / KM-RH-66KV / KM-RH-132KV - Rescue Hook </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight /> Model: KM-CVD-11KV / KM-CVD-33KV - Contact High Voltage Detector </p>



    <div className='container grid grid-three-column'>
    <div><a href="https://drive.google.com/file/d/1Fm_GnuXrfqVA-2SteRSL8wl4mOHK6YxF/view?usp=drive_link" target="_blank" rel="noreferrer">
       
        <img src={HD230}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>230HD/290HD</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1eogXD1b6YFRLtkhgXKgcE0y8bE-TASpX/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={HP275} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>275HP</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1PRnkFmuxULEwtQiFHbe401lQLCAYvIfY/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={HD276}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>276 HD</p>
      </div>

      <div>
      <a href="https://drive.google.com/file/d/1o2FVRC7Vgfv1fNB_K6CCzyyZ10VuAPyb/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={SVD286}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>286 SVD</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1sfubBWXxlq13mjch4urf0VPffbC1GuA-/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={DISCHARGEROD} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>DISCHARGE ROD</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1Vj3oiD_TDpP040gu8yCFsuOanLOxVVGj/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={HVD213} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>213 HVD</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1NATcG_71L3ykkOykC4JiDHBy2ZJnw7Rh/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={PD28} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>PD-28</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1KsiF2xFeU3Kxjd120BrbrkPb6HCTbAgU/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={PC7K}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>PC7K / PC11K / PC22K / PC33K / PC44K</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/185BHBBVt5HFYDwe__LSiTXOFeZGxcL5l/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM8000}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM8000</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/11wiAtS5XmHOUSdHvwxyV6zzmJLA9k8jX/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={HSR120}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>HSR120/120A/120B/120C/121/122</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/273HP.pdf" target="_blank" rel="noreferrer">
        <img src={HP273}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>273 HP</p>
      </div>
       <div>
      <a href="https://kusamelectrical.com/pdf/278HP.pdf" target="_blank" rel="noreferrer">
        <img src={HP278}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>278 HP</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/287SVD.pdf" target="_blank" rel="noreferrer">
        <img src={SVD287}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>287 SVD</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/288SVD.pdf" target="_blank" rel="noreferrer">
        <img src={SVD288}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>288 SVD</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/388SVD.pdf" target="_blank" rel="noreferrer">
        <img src={SVD388}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>388 SVD</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/285HD.pdf" target="_blank" rel="noreferrer">
        <img src={HD285}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>HD 285</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM-MPS-50K%20updated.pdf" target="_blank" rel="noreferrer">
        <img src={KMMPS50K}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM MP S50K</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/AC%20Line%20Separator%20ALS-1.pdf" target="_blank" rel="noreferrer">
        <img src={ALS1}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>ALS1</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/277HP-new.pdf" target="_blank" rel="noreferrer">
        <img src={HP277}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>HP277</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/375HP.pdf" target="_blank" rel="noreferrer">
        <img src={HP375}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>HP375</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20302%20SVD.pdf" target="_blank" rel="noreferrer">
        <img src={KM302SVD}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM3 02 SVD</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/Triangle%20Hot%20Stick%20HSR%20124(T)-3M.pdf" target="_blank" rel="noreferrer">
        <img src={HSR1243M}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>HSR 124 3M</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/Triangle%20Hot%20Stick%20HSR-125(T)-5M.pdf" target="_blank" rel="noreferrer">
        <img src={HSR125T5M}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>HSR 125 (T) 5M</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/ROUND%20HOT%20STICK%20HSR-131-3M.pdf" target="_blank" rel="noreferrer">
        <img src={HSR1313M}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>HSR-131-3M</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/ROUND%20%20HOT%20STICK%20HSR-132-5M.pdf" target="_blank" rel="noreferrer">
        <img src={HSR1325M}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>HSR-132-5M</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/ROUND%20%20HOT%20STICK%20HSR-135-12M.pdf" target="_blank" rel="noreferrer">
        <img src={HSR13512M}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>HSR-135-12M</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/Rescue%20Hook%20KM-RH-11KV,%20KM-RH-33KV,%20KM-RH-66KV,%20KM-RH-132KV.pdf" target="_blank" rel="noreferrer">
        <img src={KMRH11KV}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM-RH-11KV / KM-RH-33KV / KM-RH-66KV / KM-RH-132KV</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM-CVD-11%20KVKM-CVD-33%20KV.pdf" target="_blank" rel="noreferrer">
        <img src={KMCVD11KV}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM-CVD-11KV / KM-CVD-33KV</p>
      </div>
            
</div>
</div>

</div>
    
       {/* -------------HIGH VOLTAGE MEASURING INSTRUMENTS-------------- */}



  {/* -------------COATING THICKNESS GUAGE-------------- */}
      
   
  <div ref ={CoatingThicknessGuage} className='coatingthicknessguage'>
      
      <h3 style={{color:"red"}}>COATING THICKNESS GUAGE</h3>
        <div>
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> Model: KM8041 </p>
        <p  ><BiChevronsRight /> Model: KM8042 </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight /> Model:KM118 </p>
    

    <div className='container grid grid-three-column'>
    <div><a href="https://drive.google.com/file/d/1koPHrOtn8c7E4ej0aEF9XsvJgu0vr6Xb/view?usp=drive_link" target="_blank" rel="noreferrer">
       
        <img src={KM8041}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM8041</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1TCXJBkx6VMZYZ4NbxJgmBXkMSJeI2V85/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM8042}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM8042</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1-f3QnJ1p6trmlnUUwWxt3IJgdFReQgs2/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM118}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM118</p>
      </div>

            
</div>
</div>

</div>
    
       {/* -------------COATING THICKNESS GUAGE-------------- */}


  {/* -------------ELCB TESTER-------------- */}
      
   
  <div ref ={LCBTester} className='elcbtester'>
      
      <h3 style={{color:"red"}}>ELCB TESTER</h3>
        <div>
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> Model: EL1811 </p>
        <p  ><BiChevronsRight /> Model: EL1812 </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight /> Model: EL1813 </p>
    

    <div className='container grid grid-three-column'>
    <div>
      <a href="http://www.sew.com.tw/ezfiles/sew1/img/img/174753/1811EL1812EL.pdf" target="_blank" rel="noreferrer">
        <img src={EL1811}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>EL1811</p>
      </div>
    <div>
    <a href="http://www.sew.com.tw/ezfiles/sew1/img/img/174753/1811EL1812EL.pdf" target="_blank" rel="noreferrer">
        <img src={EL1812} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>EL1812</p>
      </div>
      {/* <div>
      <a href="" target="_blank" rel="noreferrer">
        <img src={EL1813} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>EL1813</p>
      </div> */}
      

            
</div>
</div>

</div>
    
       {/* -------------ELCB TESTER-------------- */}



  {/* -------------ENVIRONMENTAL TESTING INSTRUMENTS-------------- */}
     
   
  <div ref ={EnvironmentalTestingInstruments} className='environmentaltstinginstruments'>
      
      <h3 style={{color:"red"}}>ENVIRONMENTAL TESTING INSTRUMENTS</h3>
        <div>
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> Model:  KM -LUX- 99 - Digital Lux Meters   </p>
        <p  ><BiChevronsRight /> Model:  KM -LUX- 200K - Digital Lux Meters  </p>
        <p  ><BiChevronsRight />Model: KM 203 - Digital Lux Meter (Data Logger)  </p>
        <p  ><BiChevronsRight />Model: KM 963 - Wood  Moisture Meter  </p>
        <p  ><BiChevronsRight />Model: KM 963P(N) - Paper Moisture Meter  </p>
        <p  ><BiChevronsRight />Model:  KM 963-G - Grain Moisture Meter  </p>
        <p  ><BiChevronsRight />Model: 963-G-II - Grain Moisture Meter  </p>
        <p  ><BiChevronsRight />Model:  KM 917 - Concrete Moisture Meter     </p>
        <p  ><BiChevronsRight />Model: KM 918 - A Digital Hygro thermometer With Clock Alarm Function   </p>
        <p  ><BiChevronsRight /> Model: KM 920 - Temperature Humidity Meter </p>
        <p  ><BiChevronsRight /> Model: KM 600NH - Temperature / Temperature and Humidity Data Logger  </p>
        <p  ><BiChevronsRight /> Model: KM 8010 - Digital Temperature Humidity Meter With Dew Point </p>
        <p  ><BiChevronsRight /> Model: KM 8022 - Digital Temperature / Air Volume / Vane Anemometer  </p>
        <p  ><BiChevronsRight />  Model:  KM 908MK1 - Digital Humidity Meter </p>
        <p  ><BiChevronsRight /> Model: KM 730 / KM 732 / KM 733 - Irthermo-Anemometer (Hot Air Anemometer)  </p>
        <p  ><BiChevronsRight /> Model:  IRL-380  - Infrared Thermometer  </p>
        <p  ><BiChevronsRight /> Model: IRL-650 - Infrared Thermometer </p>
        <p  ><BiChevronsRight />Model: IRL-866 - Infrared Thermometer  </p>
        <p  ><BiChevronsRight /> Model: IRL-1100 - Infrared Thermometer   </p>
        <p  ><BiChevronsRight /> Model: IRL-1600  - Infrared Thermometer  </p>
        <p  ><BiChevronsRight /> Model: IRL-900 - Infrared Thermometer  </p>
        <p  ><BiChevronsRight /> Model: KM 6816-4 / KM 6816-5  - Infrared Temprature Transmitter  </p>
        <p  ><BiChevronsRight />  Model: KM - THS 03- 04-33-34 -  Three Wire Temperature & Humidity Transmitter For Indoor / Duct / Remote Type </p>
        <p  ><BiChevronsRight /> Model: KM 196 - Electromagnetic Radiation Tester    </p>
        <p  ><BiChevronsRight /> Model : KM 828  - Field Intensity Meter  </p>
        <p  ><BiChevronsRight /> Model: - KM-SPM-11 - BTU Solar Power Meter </p>
        <p  ><BiChevronsRight /> Model: KM 936 / KM 945 - Digital Thermometer Single Input K-Type / Two Input K-Type / RTD Thermometer   </p>
        <p  ><BiChevronsRight />Model: KM 926 - Digital Sound Level Meter  </p>
        <p  ><BiChevronsRight />Model: KM 928MK 1  -  Digital Sound Level Meter  </p>
        <p  ><BiChevronsRight />Model: KM 929MK 1 - Digital Sound Level Meter  </p>
        <p  ><BiChevronsRight /> Model: TM 100 - Sound Level Calibrator  </p>
        <p  ><BiChevronsRight />Model: KM 8080 MK-1 -  Digital Sound Level meter </p>
        <p  ><BiChevronsRight /> Model: KM 9122 - Temperature & Humidity Data Logger </p>
        <p  ><BiChevronsRight />Model: KM 8102 - Multifunctional Temperature & Humidity Meter  </p>
        <p  ><BiChevronsRight /> Model: KM 8122 - Multifunctional Temperature & Humidity Meter with PC interface </p>
        <p  ><BiChevronsRight /> Model: KM 8010MK-1 -  Humidity & Temperature Meter </p>
        <p  ><BiChevronsRight />Model: KM 8022MK-1 - Digital Anemometer </p>
        <p  ><BiChevronsRight /> Model: KM 910MK-1 - CFM / CMM Thermo Anemometer </p>
        <p  ><BiChevronsRight />Model: KM 8051MK-1 - Digital Lux Meter </p>
        <p  ><BiChevronsRight />Model: KM 8911A - Digital Lux(Light) Meter with PC Interface  </p>
        <p  ><BiChevronsRight /> Model: IR BT-1 - Human Body Infra Red Thermometers  </p>
        <p  ><BiChevronsRight />Model : IRL-180 - Industrial Infrared Thermometer With Human Body Temperature Measuring Function </p>
        <p  ><BiChevronsRight />Model: IRL-380(F) - Digital Infrared Thermometer  </p>
        <p  ><BiChevronsRight />Model: KM 531 - LED Light meter  </p>
        <p  ><BiChevronsRight /> Model: KM 532 - UV Light meter </p>
        <p  ><BiChevronsRight /> Model: KM 735 - Hot Wire Anemometer </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight /> Model: KM 6190 - Non contact AC voltage detector  </p>



    <div className='container grid grid-three-column'>
    <div><a href="https://drive.google.com/file/d/1LvVdxodHpOsQhddGlkNRn-bL-gWm6FNP/view?usp=drive_link" target="_blank" rel="noreferrer">
       
        <img src={KM36} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM36</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1Gpe1StjWhRO3Y7iZkjpGhZF7GMpDecw6/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM63} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM63</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1DKuOBtctKV4w74mOovno9AiPFYK_lX9c/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM192} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM192</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1rB-tuH-jEw_5nDnS51yc7cr_u4bBjuL6/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM195} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM195</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1jpqWhYT4Xs4WNZ3_d7nh4vTIJ2_YsyW8/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM600NH} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM600NH</p>
      </div>
      {/* <div>
      <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSeHdkYnhMQ1A2NW8/view?resourcekey=0-gtjboFpjNrYd7EVrPCL0ZQ" target="_blank" rel="noreferrer">
        <img src={KM601} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM601</p>
      </div> */}
      <div>
      <a href="https://drive.google.com/file/d/1peKmMeyfSMDD_WrOJwMz-GG0i2zIJrUH/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM918}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM918</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1-uj4vEyxrjY3NB-no_IQJo1X_vSSc42l/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM918A}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM918A</p>
      </div>
      {/* <div>
      <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSeFo3R3BOQ2tDaWM/view?resourcekey=0-7Y3BeLYGv8ZBqJsieWrhvA" target="_blank" rel="noreferrer">
        <img src={KM919}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM919</p>
      </div> */}
      <div>
      <a href="https://drive.google.com/file/d/1IKgTKtoT7bzgCM06plpRlluFT5qFaN0h/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM963G}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM 963-G</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1qfJ498BrskYzBul2DWUnG-UMDOCllxpk/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM963}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM 963</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1P7sDiAUI7lftTMlePu3PKntxrq-acin3/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM3961}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM3961</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1iCzY-wSQCmdjM3H8zk5SC8KswPSQMNSu/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM6185} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM6185</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1R8AZZzTBcPAw_t53wWWBJDH2X6v7XRrp/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM8010}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM8010</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1HRoqxdKMVQy79KifYNNi48ainNi6RKSA/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM8040} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM8040</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1C9WvwHv4HjpzuN8X3K7mrvbAonqNZP9Q/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM8070}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM8070</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/11sIli5cbj70FBSnekl2sNYP9ElLkgWJ5/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KMSPM11} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM-SPM-11</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1UyynxvuJ4A3fB_mzhRCUkDLe8V2wUphO/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM6502} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM6502</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1ZH4gQ75sPlBGtBh4tq6fskfJBmmcoyyA/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KMTHS03} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM-THS-03-04-33-34</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1tWYzlTeLtGloj2R6LD3NaZ_-5oTczgId/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KMLUX200K} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM-LUX-200K</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/wood_moisture_content_meter_963P.htm" target="_blank" rel="noreferrer">
        <img src={KM963P} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM963P</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20963G-II.pdf" target="_blank" rel="noreferrer">
        <img src={KM963G2} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM963G-II </p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20917.pdf" target="_blank" rel="noreferrer">
        <img src={KM917} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM917</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20920.pdf" target="_blank" rel="noreferrer">
        <img src={KM920} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM920</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20908%20MK%201.pdf" target="_blank" rel="noreferrer">
        <img src={KM908MK1} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM908MK1</p>
      </div>
      <div>
      <a href="" target="_blank" rel="noreferrer">
        <img src={KM6502} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM6502</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/IRL-380%20new.pdf" target="_blank" rel="noreferrer">
        <img src={IRL380} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>IR-L-380</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/IRL-650.pdf" target="_blank" rel="noreferrer">
        <img src={IRL650} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>IRL-650</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/IRL-866.pdf" target="_blank" rel="noreferrer">
        <img src={IRL866} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>IRL-866</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/IRL-1100.pdf" target="_blank" rel="noreferrer">
        <img src={IRL1100} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>IRL-1100</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/IRL-1600.pdf" target="_blank" rel="noreferrer">
        <img src={IRL1600} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>IRL-1600</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/IRL-900.pdf" target="_blank" rel="noreferrer">
        <img src={IRL900} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>IRL-900</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%206816-4%20&%20KM%206816-5.pdf" target="_blank" rel="noreferrer">
        <img src={KM68164} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM6816-4/KM6816-5</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM-THS-03-04-33-34.pdf" target="_blank" rel="noreferrer">
        <img src={WIRE3TRANSMITTER} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>Model KM - THS 03- 04-33-34 3-WIRE TEMPERATURE & HUMIDITY TRANSMITTER FOR INDOOR / DUCT/ REMOTE TYPE</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20926.pdf" target="_blank" rel="noreferrer">
        <img src={KM926} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM926</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20928%20MK1.pdf" target="_blank" rel="noreferrer">
        <img src={KM928MK1} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM928 MK1</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20929%20MK1.pdf" target="_blank" rel="noreferrer">
        <img src={KM929MK1} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM929 MK1</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/TM-100%20new%202015.pdf" target="_blank" rel="noreferrer">
        <img src={TM100} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>TM100</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%208080.pdf" target="_blank" rel="noreferrer">
        <img src={KM8080} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM8080</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%209122%20data%20logger.pdf" target="_blank" rel="noreferrer">
        <img src={KM9122} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM9122</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%208102.pdf" target="_blank" rel="noreferrer">
        <img src={KM8102} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM8102</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%208122%20temp%20&%20humi.pdf" target="_blank" rel="noreferrer">
        <img src={KM8122} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM8122</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%208010MK-1.pdf" target="_blank" rel="noreferrer">
        <img src={KM8010MK1} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM8010MK1</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%208022MK-1.pdf" target="_blank" rel="noreferrer">
        <img src={KM929MK1} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM929MK1</p>
      </div>
      {/* <div>
      <a href="https://kusamelectrical.com/pdf/KM%208022MK-1.pdf" target="_blank" rel="noreferrer">
        <img src={KM8022MK1} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM8022MK1</p>
      </div> */}
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20910MK-1.pdf" target="_blank" rel="noreferrer">
        <img src={KM910MK1} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM910MK1</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%208051MK-1.pdf" target="_blank" rel="noreferrer">
        <img src={KM8051MK1} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM8051MK1</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%208911A.pdf" target="_blank" rel="noreferrer">
        <img src={KM8911A} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM8911A</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/IR-BT-1-Human-Body-Thermometer.pdf" target="_blank" rel="noreferrer">
        <img src={IRBT1} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>IRBT1</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/IRL-180.pdf" target="_blank" rel="noreferrer">
        <img src={IRL180} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>IRL180</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/IRL-380(F).pdf" target="_blank" rel="noreferrer">
        <img src={IRL380F} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>IRL-380F</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20531%20LED%20light%20meter.pdf" target="_blank" rel="noreferrer">
        <img src={KM531} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM531</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20532%20uv%20light%20meter.pdf" target="_blank" rel="noreferrer">
        <img src={KM532} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM532</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%20735(332).pdf" target="_blank" rel="noreferrer">
        <img src={KM735} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM735</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM6190.pdf" target="_blank" rel="noreferrer">
        <img src={KM6190} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM6190</p>
      </div>
      


            
</div>
</div>

</div>
    
       {/* -------------ENVIRONMENTAL TESTING INSTRUMENTS-------------- */}


  {/* -------------LABORATORY INSTRUMENTS-------------- */}
      
   
  <div ref ={LaboratoryInstruments} className='laboratoryinstruments'>
      
      <h3 style={{color:"red"}}>LABORATORY INSTRUMENTS</h3>
        <div>
    
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> Model : KM 3165 - Digital Frequency counter </p>
        <p  ><BiChevronsRight />Model : KM 2001 - Digital 2MHz Function Generator  </p>
        <p  ><BiChevronsRight />Model : KM 2005 - Digital 5MHz Function Generator  </p>
        <p  ><BiChevronsRight />Model : KM 2010 - Digital 10MHz Function Generator </p>
        <p  ><BiChevronsRight /> Model:KM-OHM-8500</p>
        <p  ><BiChevronsRight /> Model:KM-20-2025</p>
        <p  ><BiChevronsRight /> Model: KM205030</p>
        <p  ><BiChevronsRight /> Model: KM-20-5030/KM-20-5060</p>
        <p  ><BiChevronsRight /> Model: KM-20-5060</p>
        <p  ><BiChevronsRight /> Model: KM2002</p>
        <p  ><BiChevronsRight /> Model: KM8045</p>
        <p  ><BiChevronsRight /> Model: SHS1062</p>
        <p  ><BiChevronsRight />Model: KM3165 </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight />Model: KM-PS-302AB/303AB/305AB/3010AB </p>
    <div className='container grid grid-three-column'>
    <div>
      <a href="https://drive.google.com/file/d/1v9HhbJS-_tnhBiozAnAeA6dGIDbCW0bv/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KMOHM8500}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM-OHM-8500</p>
      </div>
    <div>
    <a href="https://drive.google.com/file/d/18qtqevkiNiEYjiZAc-0WCTJlVj4ys3_Q/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM2010} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM-20-10</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1pDrWHQjH-sG6dmx8wnIrY41qctf5zNfH/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM202025} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM-20-2025</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1vDSZMX3elaGrgZcU3Hvyru25Y3qC2wW9/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM205030} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM205030</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1g5x5NzCYupv87Cf_FnLRds2Igw3U8NvW/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM2050305060}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM-20-5030/KM-20-5060</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1g5x5NzCYupv87Cf_FnLRds2Igw3U8NvW/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM205060} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM-20-5060</p>
      </div>
      {/* <div>
      <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSWi1KRnFMQ1RaRW8/view?resourcekey=0-PtGBM-qbvi6NxeMMMI4gmQ" target="_blank" rel="noreferrer">
        <img src={KM2002} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2002</p>
      </div> */}
      <div>
      <a href="https://drive.google.com/file/d/16Gry53FHtyUWLn9otLDvMx4EMHz9hHi0/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM3165} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM3165</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1kzabNM35-Ygn8c5XOoVueiommxPYEqeK/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM8045DMM}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM8045</p>
      </div>
      {/* <div>
      <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSVnk3QWtBX0sxTlU/view?resourcekey=0-LgwAWJJyihKHKpZeGZNruw" target="_blank" rel="noreferrer">
        <img src={SHS1062}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>SHS1062</p>
      </div> */}
      {/* <div>
      <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSTE1fTmFGR3BxSmc/view?resourcekey=0-_DSB-Ie96_hGsggFU98dIw" target="_blank" rel="noreferrer">
        <img src={DCPOWERSUPPLY} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM-PS-302AB/303AB/305AB/3010AB</p>
      </div> */}
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%202001.pdf" target="_blank" rel="noreferrer">
        <img src={KM2001} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2001</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%202005.pdf" target="_blank" rel="noreferrer">
        <img src={KM2005} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2005</p>
      </div>
      

            
</div>
</div>

</div>
    
       {/* -------------LABORATORY INSTRUMENTS-------------- */}


       {/* -------------DIGITAL ANEMOMETERS-------------- */}
  
      
   
  <div ref ={DigitalAnemometers} className='digitalanemometers'>
      
      <h3 style={{color:"red"}}>DIGITAL ANEMOMETERS</h3>
        <div>
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> MODEL: KM8022</p>
        <p  ><BiChevronsRight /> MODEL: KM909</p>
        <p style={{paddingBottom:"5rem"}}  ><BiChevronsRight /> MODEL: KM730</p>
    

    <div className='container grid grid-three-column'>
    <div>
      <a href="https://drive.google.com/file/d/1G2TeD48UxNs4XPgvzICUyqaGYjJ-8SIO/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM8022} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM8022</p>
      </div>
    <div>
    <a href="https://drive.google.com/file/d/1tnr8M7tz4P2Ap-bm9WBDKbfCX9VkOfTm/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM909}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM-909</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/15MiUrUYqIoVV1Qh_ChbQR9RPUsl98s32/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM730} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM730</p>
      </div>
      

            
</div>
</div>

</div>
    
       


       {/* -------------DIGITAL ANEMOMETERS-------------- */}





       {/* -------------DIGITAL LUX METER-------------- */}
  
      
   
       <div ref ={DigitalLuxmeter} className='digitalluxmeter'>
      
      <h3 style={{color:"red"}}>DIGITAL LUX METER</h3>
        <div>
    
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> MODEL: KM8051</p>
        <p  ><BiChevronsRight /> MODEL: KM-LUX-99</p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight /> MODEL: KM203</p>

    <div className='container grid grid-three-column'>
    <div>
      <a href="https://drive.google.com/file/d/1BByBBCVKf7aep6w5uHlyTjT0X0GJBufe/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM8051}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM8051</p>
      </div>
    <div>
    <a href="https://drive.google.com/file/d/1BLKvxoj3J6Al_FjFPU0t-5QoON6LzP4Q/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KMLUX99} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM-LUX-99</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1oyKLqEQGFYHLtMdGmZ_MMpEDEJhh4klH/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM203} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM203</p>
      </div>
      

            
</div>
</div>

</div>
    

       {/* -------------DIGITAL LUX METER-------------- */}

{/* -------------INFRARED THREMOMETERS-------------- */}
  
<div ref ={InfraredThermometers} className='infraredthermometers'>
      
      <h3 style={{color:"red"}}>INFRARED THERMOMETERS</h3>
        <div>
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> MODEL: BX-500</p>
        <p  ><BiChevronsRight /> MODEL: IR-L-300/IR-L-550/IR-L-900/IR-L-1000</p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight /> MODEL: KM688/KM690</p>
    

    <div className='container grid grid-three-column'>
    <div>
      <a href="https://drive.google.com/file/d/10ijGwiYV_Z9QKIXc5qqJ-23hbxjB32bp/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={BX500} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>BX-500</p>
      </div>
    <div>
    <a href="https://drive.google.com/file/d/1TGuEko49yF7yMKolBv7ioVQqfNULK3Jm/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={IRL300} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>IR-L-300/IR-L-550/IR-L-900/IR-L-1000</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/13v9Ocgm_Krs1nWQAt0wvMuWC1LOFmgdU/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM688} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM688/KM690</p>
      </div>
         
</div>
</div>

</div>
    

       {/* -------------INFRARED THERMOMETERS-------------- */}

{/* -------------POWER MEASUREMENT CLAMPMETERS-------------- */}
  
<div ref ={PowerMeasurementClampmeters} className='powermeasurementclampmeters'>
      
      <h3 style={{color:"red"}}>POWER MEASUREMENT CLAMPMETERS</h3>
        <div>
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> MODEL:2745 </p>
        <p  ><BiChevronsRight /> MODEL: KM-PFR-S-9-04</p>
        <p  ><BiChevronsRight /> MODEL: 2736</p>
        <p  ><BiChevronsRight /> MODEL: KM2740</p>
        <p  ><BiChevronsRight /> MODEL: KM351</p>
        <p  ><BiChevronsRight /> MODEL: KM357</p>
        <p  ><BiChevronsRight /> MODEL: KM2710</p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight /> MODEL: KM2760</p>


    

    <div className='container grid grid-three-column'>
    <div>
      <a href="https://drive.google.com/file/d/1ccima5cI6-ibkxpc7HFFc3-nTPjCy0Gc/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={K2745} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>2745</p>
      </div>
    <div>
    <a href="https://drive.google.com/file/d/1ZQW-ZhOcsgwYDEalxvIVuUqSMUVWr78T/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KMPFRS904} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM-PFR-S-9-04</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/15XSj2dzD_twWeXY_fyiVWQpZyZezBRF7/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={K2736} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>2736</p>
      </div>
      <div>
      <a href="https://www.kusamelectrical.com/single_phase_power_clampmeter.htm" target="_blank" rel="noreferrer">
        <img src={KM2740} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2740</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1noFeE4edHwHakT0HDJy5cUbk035Un0tO/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM351} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM351</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1cNM9hEs2oPq9r09vmZ7lUZLk20lu1CK2/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM357} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM357</p>
      </div>
      {/* <div>
      <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSQTVFUHN0WHJXZ28/view?resourcekey=0-X_I3Dpyug5_0lJ9-hDKBKQ" target="_blank" rel="noreferrer">
        <img src={KM2710}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2710</p>
      </div> */}
      <div>
      <a href="https://drive.google.com/file/d/1uRLfI20W8pHaaJmQsNGdj73ijXz_uvuJ/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={K2760}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>2760</p>
      </div>
         
</div>
</div>

</div>
    

       {/* -------------POWER MEASUREMENT CLAMPMETERS-------------- */}

{/* -------------POWER MEASUREMENT AND CONTROL INSTRUMENTS-------------- */}
  
<div ref ={PowerMeasurementAndControlInstruments} className='powermeasurementandcontrolinstruments'>
      
      <h3 style={{color:"red"}}>POWER MEASUREMENT AND CONTROL INSTRUMENTS</h3>
        <div>
        <p  ><BiChevronsRight />Model  2709 - 3 Phase Power Clamp On Meter With KWHR & Harmonics Measurement With PC Interface   </p>
        <p  ><BiChevronsRight />Model KM-357 - 3 Phase TRMS Power Clamp On Meter With kWHr Recording Function & PC Interface  </p>
        <p  ><BiChevronsRight />Model  KM 7200A - Multifunction TRMS Power Meter (Acc. Class 0.2%)   </p>
        <p  ><BiChevronsRight />Model  KM 7200B   </p>
        <p  ><BiChevronsRight /> Model  KM 7200C  Multifunction TRMS Power Meter With Harmonics Measurement (Acc. Class 0.2%) </p>
        <p  ><BiChevronsRight /> Model  KM 8100A - 12 Display Multifunction TRMS Power Meter (Acc. Class 0.2%) </p>
        <p  ><BiChevronsRight /> Model  KM 8100B - 12 Display Multifunction TRMS Power Meter With Demand Controller (Acc. Class 0.2%)   </p>
        <p  ><BiChevronsRight />  Model  KM 8100C - 12 Display Multifunction TRMS Power Meter With Harmonics Measurement (Acc. Class 0.2%) </p>
        <p  ><BiChevronsRight />  Model  KM 6300 </p>
        <p  ><BiChevronsRight /> Model KM-PM-100 - Digital Multifunction Trms Power Meter  </p>
        <p  ><BiChevronsRight /> Model KM 2100 / KM 2200 - Multifunction Power & Harmonics Analyzer  </p>
        <p  ><BiChevronsRight />  Model  KM-PFR-09-06 - Power Factor Regulator   </p>
        <p  ><BiChevronsRight /> Model  KM-PFR-S-04 / S-08 - Power Factor Regulator ( KVAR Type)   </p>
        <p  ><BiChevronsRight />Model KM 2300 - Multifunction Power & Harmonics Analyzer  </p>

    

    <div className='container grid grid-three-column'>
    <div>
      <a href="https://drive.google.com/file/d/1-_M_wUwkyUE4I4VOJBRp6erIURB-QJjt/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM7200A}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM 7200 A</p>
      </div>
    <div>
   
      <a href="https://drive.google.com/file/d/1IIuZwZoOSD7jyKq6xXmxiv75UBKchGP8/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM7200B} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM 7200 B</p>
      </div>
    
    <div>
      <a href="https://drive.google.com/file/d/1lkkF9WnJXKXv8zP-FZ-QZqpU5331jxkF/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM7200C}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM 7200 C</p>
      </div>
    <div>
    <a href="https://drive.google.com/file/d/1p4tfvzrwZAw8mtNKZgrdDH9RJoU0NVO-/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM8100A} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM 8100 A</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1T0IKhDgJG3tL57PNBwqPmc7KimOxwmlP/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM8100B} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM 8100 B</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1VLqzpPNEOlFtQHWhDKEmAx1aA2HqikZu/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM8100C}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM8100C</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1gmt6YHDqWpcUJX2dKHVT8Wczae9oLIts/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM6300}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM6300</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1UisXv0K0l4qd0UYK9wNxtKPC3MIhS1Vz/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KMPM100} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM-PM-100</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/1gGhuBsGOxAMBxxqqFkyo48XcyurQjXmW/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM9600} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM9600</p>
      </div>
      {/* <div>
      <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSbWRZVXFza3ltRlE/view?resourcekey=0-tuufoNl2AXw26VTaIDuehw" target="_blank" rel="noreferrer">
        <img src={RKMEA101} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>R-KM-EA-101/R-KM-EA-102/R-KM-EA-103 R-KM-EA-104</p>
      </div> */}
      <div>
      <a href="https://kusamelectrical.com/pdf/2709.pdf" target="_blank" rel="noreferrer">
        <img src={K2709} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>2709</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%202100catalog.pdf" target="_blank" rel="noreferrer">
        <img src={KM2100} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2100</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM-PFR-S-9-04-08.pdf" target="_blank" rel="noreferrer">
        <img src={KMPFRS04} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM-PFR-S-04</p>
      </div>
      <div>
      <a href="https://kusamelectrical.com/pdf/KM%202300.pdf" target="_blank" rel="noreferrer">
        <img src={KM2300} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM2300</p>
      </div>
    
         
</div>
</div>

</div>
    

       {/* -------------POWER MEASUREMENT AND CONTROL INSTRUMENTS-------------- */}


{/* -------------POWER FACTOR REGULATOR-------------- */}
  
<div ref ={PowerFactorRegulator} className='powerfactorregulator'>
      
      <h3 style={{color:"red"}}>POWER FACTOR REGULATOR</h3>
        <div>
        <p  ><BiChevronsRight />  Model  KM-PFR-9-06 </p>
        {/* <p  ><BiChevronsRight />  Model  KM-PFR-S-9-04/KM-PFR-S-9-08/KM-PFR-S-9-12 </p> */}
        

    <div className='container grid grid-three-column'>
    <div>
      <a href="https://drive.google.com/file/d/1bCH6UdJvNRlw8fMJeIJTKOz-cUfO8QP4/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KMPFR906} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM-PFR-9-06</p>
      </div>
    {/* <div>
    <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSWDFIRGdMTFlKaEU/view?resourcekey=0-zfaBSGNpo0qLpfrJ2u5lwg" target="_blank" rel="noreferrer">
        <img src={KMPFRS908} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM-PFR-S-9-04/KM-PFR-S-9-08/KM-PFR-S-9-12</p>
      </div> */}
     
         
</div>
</div>

</div>
    

       {/* -------------POWER FACTOR REGULATOR-------------- */}

{/* -------------PROGRAMMABLE DIGITAL PANEL METERS-------------- */}
  
<div ref ={ProgrammableDigitalPanelMeters} className='programmabledigitalpanelmeters'>
      
      <h3 style={{color:"red"}}>PROGRAMMABLE DIGITAL PANEL METERS</h3>
        <div>
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight />  Model: MMV/ MMVR : AC voltage Digital meter </p>
        <p  ><BiChevronsRight />  Model: MMA / MMAR : AC Current Digital Metter </p>
        <p  ><BiChevronsRight />  Model: MMD : DC Voltage, Current Digital Meter</p>
        <p  ><BiChevronsRight />  Model: MMF: Frequency Digital Meter </p>
        <p  ><BiChevronsRight />  Model: MMG: Modbus-RTU Digital Meter </p>
        <p  ><BiChevronsRight />  Model: MMPF: Power Factor Digital Meter </p>
        <p  ><BiChevronsRight />  Model: MMQ / MMQH : Var Digital Meter </p>
        <p  ><BiChevronsRight />  Model: MMR : RTD Temperature Digital Meter</p>
        <p  ><BiChevronsRight />  Model: MMS : DC Rated & Setting Digital Meter </p>
        <p  ><BiChevronsRight />  Model: MMT : Thermocouple Temperature Digital Meter</p>
        <p  ><BiChevronsRight />  Model: MMW : Watt Digital Meter</p>
        <p  ><BiChevronsRight />  Model: MMWH : Watthour Digital Meter</p>
        <p  ><BiChevronsRight />  Model: MMWWH : Watt Watthour Digital Meter</p>
        <p  ><BiChevronsRight />  Model: DM3A : 3 Phase AC Current Digital Meter </p>
        <p  ><BiChevronsRight />  Model: DM3D : 3 Channel DC Voltage, Current Digital Meter </p>
        <p  ><BiChevronsRight />  Model: DM3S : 3 Channel Current Loop DC Rated Digital Meter</p>
        <p  ><BiChevronsRight />  Model: DM3V : 3 Phase AC Voltage Digital Meter </p>
        <p  ><BiChevronsRight />  Model: DMB : Microprocesser Load Cell, Strain Guage & Setting Digital Meter </p>
        <p  ><BiChevronsRight />  Model: DMDPW : DC Multifunction Power Meter</p>
        <p  ><BiChevronsRight />  Model: DMF : Microprocesser AC Frequency & Setting Digital Meter </p>
        <p  ><BiChevronsRight />  Model: DML : Microprocesser Flow Digital Meter</p>
        <p  ><BiChevronsRight />  Model: DMPF : Microprocesser Power Factor Digital Meter </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight />  Model: DMU : Microprocesser RPM, SPEED, PULSE, LENGTH, FLUID & Setting Digital Meter</p>

    

    <div className='container grid grid-three-column'>
    <div>
      <a href="https://drive.google.com/file/d/1splz2QVcBk1GZNODpwULmFWWVlFaP-XW/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={DM3A}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>DM3A</p>
      </div>
    <div>
    <a href="https://drive.google.com/file/d/1jG74GNE-_JSdG1ucfi9pKCNZw5JT5E5r/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={DM3V}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>DM3V</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1Tp_MpPGHSo42fCpgJu9tlOVvbVuSJPeK/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={DMAR} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>DMA/DMAR</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1eyDnRA3YjAV1ODQPShBVJUA24JyBtUDc/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={DMD}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>DMD</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1eGU97IoZ8Z9Q9M56_YqvNOS09SHvlVKW/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={DMDPW} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>DMDPW</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1n8mxcPBKriw_mPC6giuuKjjCWVh4Ig99/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={DME}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>DME</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1zR4AFTpMzyPM19pwThVuh853DcjTisUf/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={DMF}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>DMF</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/140WAECzmtmZYb35kakiWZyyCY9vLlaxt/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={DML} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>DML</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/15m13sYIG3zAnloNgXAN4mfw4i94osRNW/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={DMP}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>DMP</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1-4K0EOTRa67vNN5RSCS7UPfuvvSTvOxH/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={DMPF}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>DMPF</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1W9kHge5tKCpL28K-4r_ssWaKT8J4eLvj/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={DMU}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>DMU</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1asoT8CI55_Y3L0UlL3rmDerDZA6gRGfT/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={DMV}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>DMV/DMVR</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1B_5nQvWAR9oVWBhHynAv0vHz93j1Uczy/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={DMVA} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>DMVA</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1nybFN3Nbw-CSC4Zirx30A5CCpn-dZ6Zu/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={DMW} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>DMW/DMQ</p>
      </div>
      {/* <div>
    <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSS0pjSnc5UnJEMGM/view?resourcekey=0-InlDE6STYLkieN2aoHB_8Q" target="_blank" rel="noreferrer">
        <img src={DMWH}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>DMWH/DMQH</p>
      </div> */}
      <div>
    <a href="https://kusamelectrical.com/Panel_Meter/MMV-MMVR%20Panel%20Meter.pdf" target="_blank" rel="noreferrer">
        <img src={MMV} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>MMV/MMVR</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/Panel_Meter/MMA-MMAR%20AC%20current%20Panelmeter.pdf" target="_blank" rel="noreferrer">
        <img src={MMA} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>MMA/MMAR</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/Panel_Meter/MMD%20panel%20meter.pdf" target="_blank" rel="noreferrer">
        <img src={MMD} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>MMD</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/Panel_Meter/MMF%20Frequency%20Digital%20Meter.pdf" target="_blank" rel="noreferrer">
        <img src={MMF} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>MMF</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/Panel_Meter/MMG%20Panel%20meter.pdf" target="_blank" rel="noreferrer">
        <img src={MMG} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>MMG</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/Panel_Meter/MMF%20Frequency%20Digital%20Meter.pdf" target="_blank" rel="noreferrer">
        <img src={MMPF} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>MMPF</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/Panel_Meter/MMQH%20panel%20meter.pdf" target="_blank" rel="noreferrer">
        <img src={MMQH} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>MMQH</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/Panel_Meter/MMR%20panel%20meter.pdf" target="_blank" rel="noreferrer">
        <img src={MMR} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>MMR</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/Panel_Meter/MMS%20PANEL%20METER.pdf" target="_blank" rel="noreferrer">
        <img src={MMS} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>MMS</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/Panel_Meter/MMT%20panel%20meter.pdf" target="_blank" rel="noreferrer">
        <img src={MMT} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>MMT</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/Panel_Meter/MMW%20Watt%20digital%20meter.pdf" target="_blank" rel="noreferrer">
        <img src={MMW} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>MMW</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/Panel_Meter/MMWH%20Panel%20Meter.pdf" target="_blank" rel="noreferrer">
        <img src={MMWH} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>MMWH</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/Panel_Meter/MMWWH%20Panel%20Meter.pdf" target="_blank" rel="noreferrer">
        <img src={MMWWH} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>MMWWH</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/Panel_Meter/DM3D%20Panel%20Meter.pdf" target="_blank" rel="noreferrer">
        <img src={DM3D} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>DM3D</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/Panel_Meter/DM3S%20Panel%20Meter.pdf" target="_blank" rel="noreferrer">
        <img src={DM3S} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>DM3S</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/Panel_Meter/DMB%20Panel%20Meter.pdf" target="_blank" rel="noreferrer">
        <img src={DMB} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>DMB</p>
      </div>
   
     
         
</div>
</div>

</div>
    

       {/* -------------PROGRAMMABLE DIGITAL PANEL METERS-------------- */}

{/* -------------CALIBRATORS-------------- */}
  
<div ref ={Calibrators} className='calibrators'>
      
      <h3 style={{color:"red"}}>CALIBRATORS</h3>
        <div>
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight />  Model:KM3600 </p>
        <p  ><BiChevronsRight />  Model: KM-CAL-10 </p>
        <p  ><BiChevronsRight />  Model: KM-CAL-18</p>
        <p  ><BiChevronsRight />  Model: KM-CAL-700</p>
        <p  ><BiChevronsRight />  Model:KM-CAL-901 </p>
        <p  ><BiChevronsRight />  Model:KM-CAL-902 </p>
        <p  ><BiChevronsRight />  Model:KM-CAL-903 </p>
        <p  ><BiChevronsRight />  Model:KM-CAL- 710 - Thermocouple Calibrator </p>
        <p  ><BiChevronsRight />  Model:KM-CAL- 720 - RTD Calibrator  </p>
        <p  ><BiChevronsRight /> Model KM-CAL- 904MK-1 - Loop Calibrator  </p>
        <p  ><BiChevronsRight /> Model  KM 3600 - Digital High Precision Hand-Held Thermocouple Calibrator </p>
        <p  ><BiChevronsRight /> Model KM-CAL- 801 - Calibrator  </p>
        <p  ><BiChevronsRight /> Model KM-CAL- 802 - Calibrator  </p>
        <p  ><BiChevronsRight /> Model KM-CAL- 803 - RTD Calibrator  </p>
        <p  ><BiChevronsRight /> Model KM-CAL- 804 - Calibrator </p>
        <p  ><BiChevronsRight /> Model KM-CAL- 805 -Calibrator   </p>
        <p  ><BiChevronsRight /> Model KM-CAL-905 - Digital High Precision Hand-Held Multifunction Calibrator (Source & Sink)  </p>
        <p  ><BiChevronsRight /> Model KM-CAL- 906 - Temperature Calibrator  </p>
        <p  ><BiChevronsRight /> Model KM-CAL- 907 - V/mA Process Calibrator </p>
        <p  ><BiChevronsRight /> Model KM 26 / 26H - Hart Multifunction Calibrator  </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight />  Model KM-CAL-905A - Digital High Precision Hand-Held Multifunction Calibrator (Source & Sink) without Pressure digital_function_generator_2005 </p>



    <div className='container grid grid-three-column'>
    <div>
      <a href="https://drive.google.com/file/d/1HkdBXvutyR2EcYLhL2bksoRuGNF566wS/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM3600} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM3600</p>
      </div>
    {/* <div>
    <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSR1VSWjhmUjdCaFE/view?resourcekey=0--v0gUc2qpSc4Gc1OVa3A3w" target="_blank" rel="noreferrer">
        <img src={KMCAL10} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM-CAL-10</p>
      </div> */}
      {/* <div>
    <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSMk5aNm14Rlo0dUk/view?resourcekey=0-4WV_Uz9gkwuzTIZtp25huw" target="_blank" rel="noreferrer">
        <img src={KMCAL18} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM-CAL-18</p>
      </div> */}
      <div>
    <a href="https://drive.google.com/file/d/1iH5vnynFdY34WvXVgTEUJWltlFnOZ4E7/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KMCAL700} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM-CAL-700</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1NAcRIhvkYeTMM-qTdz_Ewaf2yNBvPs0v/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KMCAL710} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM-CAL-710</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1nYjN-9pVDBY9Z5Ew25YBH-cA5zmMyL7v/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KMCAL720} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM-CAL-720</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1EX8jAp-4NH1s1kr5F4hw99aGYTIHCLb0/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KMCAL901} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM-CAL-901</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1eVsDNB7xZDuMUmDkUn4ORfWUIKeMZqxQ/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KMCAL902} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KMCAL902</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1zZGTZXcKOM-PdY2UPdPBEwV5mZqoGRUD/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KMCAL903} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM-CAL-903</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1KlD4qI5hZpcmIqqDXiKBz0KSENPZyWBk/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KMCAL907} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM-CAL-907</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1LTkWJ4YWaFTMhie3Z8cQPMo4jMk3Z8VE/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KMCAL906} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM-CAL-906</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1yJavH5Yxhp5bcN2hM1ykJSuzh7kMZZ4t/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KMCAL905}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM-CAL-905</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1_Jqc0H63g8N_aRbW1yWujuqpme4VfwcA/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KMCAL904MK1} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM-CAL-904MK-1</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/KM-CAL-801.pdf" target="_blank" rel="noreferrer">
        <img src={KMCAL801} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KMCAL801</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/KM-CAL-802.pdf" target="_blank" rel="noreferrer">
        <img src={KMCAL802} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KMCAL802</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/KM-CAL-803.pdf" target="_blank" rel="noreferrer">
        <img src={KMCAL803} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KMCAL803</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/KM-CAL-804.pdf" target="_blank" rel="noreferrer">
        <img src={KMCAL804} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KMCAL804</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/KM-CAL-805.pdf" target="_blank" rel="noreferrer">
        <img src={KMCAL805} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KMCAL805</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/Hart%20Multifunction%20calibrator%2026%20and%2026H.pdf" target="_blank" rel="noreferrer">
        <img src={KM26} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM26/26H</p>
      </div>    
  
         
</div>
</div>

</div>
         {/* -------------CALIBRATORS-------------- */}




       {/* -------------GAS DETECTORS-------------- */}


  
<div ref ={GasDetectors} className='gasanalyser'>
      
      <h3 style={{color:"red"}}>GAS DETECTORS</h3>
        <div>
    
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> Model: KM303 </p>
        <p  ><BiChevronsRight /> Model: KM310  </p>
        <p  ><BiChevronsRight /> Model: KM5430 </p>
        <p  ><BiChevronsRight /> Model: KM5460  </p>
        <p  ><BiChevronsRight /> Model: KM5470  </p>
        <p  ><BiChevronsRight /> Model: KM5480 </p>
        <p  ><BiChevronsRight /> Model : KM 315 - Refrigeration Gas Leak Detector   </p>
        <p  ><BiChevronsRight /> Model : KM 5420A - Combustible Gas Detector  </p>
        <p  ><BiChevronsRight /> Model : KM 5490 - Oxygen Detector  </p>
        <p  ><BiChevronsRight /> Model : KM-CO-910 - Mini Carbon Monoxide Gas Detector  </p>
        <p  ><BiChevronsRight /> Model : E1000 - Portable Single Gas Detector (Any 1 Gas)  </p>
        <p  ><BiChevronsRight /> Model : E4000 - Portable Multi Gas Detector (Any 4 Gases) </p>
        <p  ><BiChevronsRight /> Model : E6000 - Multi Gas Detector  </p>
        <p  ><BiChevronsRight /> Model : KM 5491 - Oxygen Meter </p>
        <p  ><BiChevronsRight /> Model : KM GD-04 Portable Multigas Detector For CO,O2, H2S, Ex </p>
        <p  ><BiChevronsRight />  Model : KM 840-LEL - Portable Combustible Gas Detector For LEL </p>
        <p  ><BiChevronsRight /> Model : KM 840-O2 - Portable Combustible Gas Detector For O2  </p>
        <p  ><BiChevronsRight /> Model : KM 7460 - Carbon Dioxide(Co2)Data Logger Meter(NDIR)  </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight /> Model : KM 313 - Refrigerant Leakage Detector  </p>





    <div className='container grid grid-three-column'>
    <div>
      <a href="https://drive.google.com/file/d/1YbleXq1BF1nXLmN9dT6sOcegd9opzmgG/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM303}  className="hover" style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM 303</p>
      </div>
    <div>
    <a href="https://drive.google.com/file/d/1Nk8wU8bmtSnxkYxId7cmMFZY-jHjRHkM/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM310}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM310</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1t45vb7FBvHCWv_NcSPvHMN6zNwHpgX3T/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM5430}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM 5430</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1gF5SCFoQLEvYGY2yfyPvLOHpjpzCqPBG/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM5460} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM 5460</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1c_vwaG5bSvZ3STBWxpSKy4jGN8son9Ht/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM5470} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM5470</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1BCzKJ7vlGxH2tKg0r66BYTN-FUA0mEwN/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM5480}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM 5480</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1xGV4o3sQZycoH8-6r9z5NwTap1B-6fjj/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM5490} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM 5490</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/KM%20315.pdf" target="_blank" rel="noreferrer">
        <img src={KM315} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM315</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/KM%205420A.pdf" target="_blank" rel="noreferrer">
        <img src={KM5420A} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM5420A</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/KM-CO-910.pdf" target="_blank" rel="noreferrer">
        <img src={KMCO910} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KMCO910</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/E1000.pdf" target="_blank" rel="noreferrer">
        <img src={E1000} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>E1000</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/E4000%20-2017.pdf" target="_blank" rel="noreferrer">
        <img src={E4000} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>E4000</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/E%206000.pdf" target="_blank" rel="noreferrer">
        <img src={E6000} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>E6000</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/KM%205491.pdf" target="_blank" rel="noreferrer">
        <img src={KM5491} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM5491</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/KM-GD-04.pdf" target="_blank" rel="noreferrer">
        <img src={KMGD04} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KMGD04</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/KM-840-LEL.pdf" target="_blank" rel="noreferrer">
        <img src={KM840LEL} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM-840-LEL</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/KM-840-O2.pdf" target="_blank" rel="noreferrer">
        <img src={KM840O2} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM840O2</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/KM%207460.pdf" target="_blank" rel="noreferrer">
        <img src={KM7460} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM7460</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/KM%20313(380).pdf" target="_blank" rel="noreferrer">
        <img src={KM313} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM313</p>
      </div>
     
         
</div>
</div>

</div>
    
       {/* -------------GAS ANALYSER-------------- */}



       {/* ---------------RECORDERS-ANALYSERS-------------------- */}


  
<div ref ={RecordersAnalyser} className='recordersanalyser'>
      
      <h3 style={{color:"red"}}>RECORDERS-ANALYSERS</h3>
        <div>
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> Model: RECORDERS CATALOG </p>
        <p  ><BiChevronsRight /> Model: RECORDERS </p>
        <p  ><BiChevronsRight /> Model: SYSTEMANALYSER </p>
        <p  style={{paddingBottom:"5rem"}}><BiChevronsRight /> Model: ENERGY ANALYSER R-KM-EA-101/R-KM-EA-102/R-KM-EA-103/R-KM-EA-104  </p>

    

    <div className='container grid grid-three-column'>
    <div>
      <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSTjNLc2VLR09HMms/view?resourcekey=0-J519V7aEyNzg28kDzMD8tw" target="_blank" rel="noreferrer">
        <img src={RECORDERSCATALOG} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>RECORDERS CATALOG</p>
      </div>
    <div>
    <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSMFpqQU4zQkNxX0k/view?resourcekey=0-RYtNfxsB5-TUYYy_IRKl5w" target="_blank" rel="noreferrer">
        <img src={RECORDERS}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>RECORDERS</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSbDRJamNiU1h4U1E/view?resourcekey=0-ccLcAXNEnQqDQxkmZp8Bvw" target="_blank" rel="noreferrer">
        <img src={SYSTEMANALYSER} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>SYSTEMANALYSER</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sScDB0djhkcnlJdzg/view?resourcekey=0-VfMMEmtP1TC_qQDaCc3l4Q" target="_blank" rel="noreferrer">
        <img src={ENERGYANALYSER} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>ENERGY ANALYSER R-KM-EA-101/R-KM-EA-102/R-KM-EA-103/R-KM-EA-104</p>
      </div>
     
         
</div>
</div>

</div>
    

       {/* --------------------RECORDERS-ANALYSERS-------------- */}

 {/* -------------BANKING SOLUTIONS-------------- */}


  
 <div ref ={CurrencyCounting} className='currencycounting'>
      
 <h3 style={{color:"red"}}>BANKING SOLUTIONS</h3>
        <div>
        <p style={{paddingTop:"5rem"}} ><BiChevronsRight /> Model: KCM PLUS DT </p>
        <p  ><BiChevronsRight /> Model: KM 9000 </p>
        <p  ><BiChevronsRight /> Model:  KM 9010 - KM9011 Currency Counter  </p>
        <p  ><BiChevronsRight /> Model: KM 9012 Loose Note Counting Machine</p>
        <p  ><BiChevronsRight /> Model: 9018 Loose Note Currency Counting Machine</p>
        <p  ><BiChevronsRight /> Model:9027 Handy Currency Note Counter  </p>
        <p  ><BiChevronsRight /> Model: KM 9045 Ultra Violet Fake Note Detector With Magnifying Glass </p>
        <p  ><BiChevronsRight /> Model: KM9011A Loose Note Counting Machine </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight /> Model: KM9017 Bank Note Sorter </p>

    

    <div className='container grid grid-three-column'>
    
    {/* <div>
      <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSbHVySUZjZ3VWcmc/view?resourcekey=0-LIj7sFQM_kS7UN_SF0MigA" target="_blank" rel="noreferrer">
        <img src={KCMPLUSDT} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KCM PLUS DT</p>
      </div> */}
    <div>
    <a href="https://drive.google.com/file/d/1GkzZ-DYS_t_Cp9min-LKQ6Qzm3eIQX2w/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM9000}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM 9000</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1WVWMpwKvSXa9ICR80hrCNjgZK2BBZzO8/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM9010}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM 9010 - KM9011</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1uipeqjKtnmTfOntxb6rBwr6f0Zrx3hnm/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM9012}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM 9012</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1jZ48lkfEmwrlWjtOc1PmobysnTUeY4xZ/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={K9018} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>9018</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1Dho0uu0I2F7XyKeisJYQwKh4uwb9LwvI/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={K9027} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>9027</p>
      </div>
      <div>
    <a href="https://drive.google.com/file/d/1FdyakC4x8ZpeNuerrAcrqg3WMKQXDWdY/view?usp=drive_link" target="_blank" rel="noreferrer">
        <img src={KM9045}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM 9045</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/KM%209011A.pdf" target="_blank" rel="noreferrer">
        <img src={KM9011A}  className="hover" style={{ width: 120, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM9011A</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/KM%209017%20Note%20sorter.pdf" target="_blank" rel="noreferrer">
        <img src={KM9017}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>KM9017</p>
      </div>
     
         
</div>
</div>

</div>
    
       {/* -------------BANKING SOLUTIONS-------------- */}


 {/* -------------DISCHARGE 1 PHASE AND 3 PHASE RODS-------------- */}


  {/* -------------SINGLE PHASE--------------- */}
 <div ref ={SinglePhase} className='singlephase'>
      
 <h3 style={{color:"red"}}>SINGLE PHASE</h3>
        <div>

        <p  style={{paddingTop:"2rem"}} ><BiChevronsRight /> Single Phase Highly Insulated FRP Telescopic Earth / Discharge Rod 11-33kv</p>
        <p  ><BiChevronsRight />Single Phase Highly Insulated FRP Telescopic Earth / Discharge Rod 66-132k </p>
        <p  ><BiChevronsRight /> Single Phase Highly Insulated FRP Telescopic Earth / Discharge Rod 33-66kv</p>
        <p  ><BiChevronsRight /> Single Phase Highly Insulated FRP Telescopic Earth / Discharge Rod 132-220KV</p>
        <p  ><BiChevronsRight />Single Phase Highly Insulated FRP Telescopic Earth / Discharge Rod 11kV </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight />Single Phase Highly Insulated FRP Telescopic Earth / Discharge Rod 220KV-400KV </p>


    <div className='container grid grid-three-column'>
    <div>
      <a href="https://kusamelectrical.com/pdf/Discharge%20Rod%2011-33kv%201%20phase%20.pdf" target="_blank" rel="noreferrer">
        <img src={KV11} className="hover"  style={{ width: 50, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>11-33KV</p>
      </div>
    <div>
    <a href="https://kusamelectrical.com/pdf/Discharge%20Rod%20%2066-132kv%201%20phase.pdf" target="_blank" rel="noreferrer">
        <img src={KV66}  className="hover" style={{ width: 50, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>66-132KV</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/Discharge%20Rod%2033-66kv%201%20phase.pdf" target="_blank" rel="noreferrer">
        <img src={KV33}  className="hover" style={{ width: 50, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>33-66KV</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/Discharge%20Rod%20132-220kv%201%20phase.pdf" target="_blank" rel="noreferrer">
        <img src={KV132}  className="hover" style={{ width: 50, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>132-220KV</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/Discharge%20Rod%2011kv%201%20phase%20.pdf" target="_blank" rel="noreferrer">
        <img src={KV11ROD} className="hover"  style={{ width: 50, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>11KV</p>
      </div>
      <div>
    <a href="https://kusamelectrical.com/pdf/Discharge%20Rod%20%20220KV-400KV%201%20phase.pdf" target="_blank" rel="noreferrer">
        <img src={KV220} className="hover"  style={{ width: 50, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
      </a>
      <p>220KV/400KV</p>
      </div>
    
</div>
</div>

</div>
  {/* -------------SINGLE PHASE--------------- */}

    {/* -------------THREE PHASE--------------- */}
 <div ref ={ThreePhase} className='threephase'>
      
      <h3 style={{color:"red"}}>THREE PHASE</h3>
             <div>
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> Three Phase Highly Insulated FRP Telescopic Earth / Discharge Rod 11 kv</p>
        <p  ><BiChevronsRight /> Three Phase Highly Insulated FRP Telescopic Earth / Discharge Rod 11-33kv </p>
        <p  ><BiChevronsRight /> Three Phase Highly Insulated FRP Telescopic Earth / Discharge Rod 33-66kv</p>
        <p  ><BiChevronsRight />Three Phase Highly Insulated FRP Telescopic Earth / Discharge Rod 66-132kv </p>
        <p  ><BiChevronsRight /> Three Phase Highly Insulated FRP Telescopic Earth / Discharge Rod 132-220kv</p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight />Three Phase Highly Insulated FRP Telescopic Earth / Discharge Rod 220KV-400KV </p>


         <div className='container grid grid-three-column'>
         <div>
           <a href="https://kusamelectrical.com/pdf/Discharge%20Rod%2011kv%203Phase.pdf" target="_blank" rel="noreferrer">
             <img src={KV11THREE} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>11KV</p>
           </div>
         <div>
         <a href="https://kusamelectrical.com/pdf/Discharge%20Rod%2011-33kv%203Phase.pdf" target="_blank" rel="noreferrer">
             <img src={KV33THREE}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
           </a>
           <p>11-33KV</p>
           </div>
           <div>
         <a href="https://kusamelectrical.com/pdf/Discharge%20Rod%2033-66kv%203%20Phase.pdf" target="_blank" rel="noreferrer">
             <img src={KV66THREE}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
           </a>
           <p>33-66KV</p>
           </div>
           <div>
         <a href="https://kusamelectrical.com/pdf/Discharge%20Rod%2066-132kv%203%20Phase.pdf" target="_blank" rel="noreferrer">
             <img src={KV132THREE}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
           </a>
           <p>66-132KV</p>
           </div>
           <div>
         <a href="https://kusamelectrical.com/pdf/Discharge%20Rod%20132-220kv%203Phase.pdf" target="_blank" rel="noreferrer">
             <img src={KV220THREE} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
           </a>
           <p>132-220KV</p>
           </div>
           <div>
         <a href="https://kusamelectrical.com/pdf/Discharge%20Rod%20220KV-400KV%203%20Phase.pdf" target="_blank" rel="noreferrer">
             <img src={KV400THREE} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
           </a>
           <p>220KV/400KV</p>
           </div>
         
     </div>
     </div>
     
     </div>
       {/* -------------THREE PHASE--------------- */}
    
  {/* -------------HIGHLY INSULATED FRP TELESCOPIC EARTH / DISCHARGE ROD--------------- */}
  <div ref ={TelescopicEarth} className='telescopicearth'>
      
      <h3 style={{color:"red"}}>HIGHLY INSULATED FRP TELESCOPIC EARTH / DISCHARGE ROD</h3>
             <div>
        <p style={{paddingTop:"2rem"}}><BiChevronsRight /> Model : KM-HKDR-11KV - Hook Type Highly Insulated FRP Telescopic Earth / Discharge Rod </p>
        <p  ><BiChevronsRight /> Model : KM-HKDR-33KV - Hook Type Highly Insulated FRP Telescopic Earth / Discharge Rod </p>
        <p  ><BiChevronsRight />Model : KM-HKDR-66KV - Hook Type Highly Insulated FRP Telescopic Earth / Discharge Rod </p>
        <p  ><BiChevronsRight />Model : KM-HKDR-132KV - Hook Type Highly Insulated FRP Telescopic Earth / Discharge Rod </p>
        <p  ><BiChevronsRight />Model : KM-HKDR-220KV - Hook Type Highly Insulated FRP Telescopic Earth / Discharge Rod </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight />Model : KM-HKDR-400KV - Hook Type Highly Insulated FRP Telescopic Earth / Discharge Rod </p>


         <div className='container grid grid-three-column'>
         <div>
           <a href="https://kusamelectrical.com/pdf/Rescue%20Hook%20KM-RH-11KV,%20KM-RH-33KV,%20KM-RH-66KV,%20KM-RH-132KV.pdf" target="_blank" rel="noreferrer">
             <img src={KMRH33KV} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM-RH-11KV/KM-RH-33KV/KM-RH-66KV/KM-RH-132KV</p>
           </div>
         <div>
         <a href="https://kusamelectrical.com/pdf/KM-HKDR-33KV.pdf" target="_blank" rel="noreferrer">
             <img src={KMHKDR33KV}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
           </a>
           <p>KM-HKDR-33KV</p>
           </div>
           <div>
         <a href="https://kusamelectrical.com/pdf/KM-HKDR-66KV.pdf" target="_blank" rel="noreferrer">
             <img src={KMHKDR66KV}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
           </a>
           <p>KM-HKDR-66KV</p>
           </div>
           <div>
         <a href="https://kusamelectrical.com/pdf/KM-HKDR-132KV.pdf" target="_blank" rel="noreferrer">
             <img src={KMHKDR132KV}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
           </a>
           <p>KM-HKDR-132KV</p>
           </div>
           <div>
         <a href="https://kusamelectrical.com/pdf/KM-HKDR-220KV.pdf" target="_blank" rel="noreferrer">
             <img src={KMHKDR220KV} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
           </a>
           <p>KM-HKDR-220KV</p>
           </div>
           <div>
         <a href="https://kusamelectrical.com/pdf/KM-HKDR-220KV.pdf" target="_blank" rel="noreferrer">
             <img src={KMHKDR220KV2} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
           </a>
           <p>KM-HKDR-220KV2</p>
           </div>
         
     </div>
     </div>
     
     </div>
  {/* -------------HIGHLY INSULATED FRP TELESCOPIC EARTH / DISCHARGE ROD--------------- */}
       {/* -------------DISCHARGE 1 PHASE AND 3 PHASE RODS-------------- */}


{/* -------------POWER TRANSDUCERS--------------- */}
<div ref ={PowerTransducers} className='powertransducers'>
      
      <h3 style={{color:"red"}}>POWER TRANSDUCERS</h3>
             <div>
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight />Model: TMRT - Multifunction Power Transducer </p>
        <p  ><BiChevronsRight />Model : TDV - AC Voltage Transducer  </p>
        <p  ><BiChevronsRight />Model: TDA - AC Current Transducer    </p>
        <p  ><BiChevronsRight />Model : TDW / TDW2 / TDQ2 - WATT or VAR Transducer  </p>
        <p  ><BiChevronsRight /> Model : TDWQ2 - WATT / VAR Transducer  </p>
        <p  ><BiChevronsRight /> Model :TDWH - Watt / Watthour Transducer </p>
        <p  ><BiChevronsRight /> Model: TDWWH - Watthour or Varhour Transducer </p>
        <p  ><BiChevronsRight />Model : TDPF - Power Factor Transducer </p>
        <p  ><BiChevronsRight /> Model : TDF - Frequency Transducer </p>
        <p  ><BiChevronsRight /> Model : HC-6610 - Multifunction Power Transducer </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight /> Model : TSCT / TFCT - AC Current Isolated Sensor Two / Four Wire Transducer </p>



         <div className='container grid grid-three-column'>
         <div>
           <a href="https://kusamelectrical.com/pdf/TRMT%20Transducer.pdf" target="_blank" rel="noreferrer">
             <img src={TRMT} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>TRMT</p>
           </div>
         <div>
         <a href="https://kusamelectrical.com/pdf/AC%20VOLTAGE%20TRANSDUCER%20MODEL-TDV.pdf" target="_blank" rel="noreferrer">
             <img src={TDV}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
           </a>
           <p>TDV</p>
           </div>
           <div>
         <a href="https://kusamelectrical.com/pdf/AC%20CURRENT%20TRANSDUCER%20MODEL-TDA.pdf" target="_blank" rel="noreferrer">
             <img src={TDA}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
           </a>
           <p>TDA</p>
           </div>
           <div>
         <a href="https://kusamelectrical.com/pdf/WATT%20OR%20VAR%20TRANSDUCER%20MODEL-TDW.pdf" target="_blank" rel="noreferrer">
             <img src={TDW}  className="hover" style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
           </a>
           <p>TDW</p>
           </div>
           <div>
         <a href="https://kusamelectrical.com/pdf/WATT%20%20-%20%20VAR%20TRANSDUCER%20MODEL-TDWQ2.pdf" target="_blank" rel="noreferrer">
             <img src={TDWQ2} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
           </a>
           <p>TDWQ2</p>
           </div>
           <div>
         <a href="https://kusamelectrical.com/pdf/WATTHOUR%20%20OR%20%20VARHOUR-%20%20TRANSDUCER%20MODEL-%20TDWH.pdf" target="_blank" rel="noreferrer">
             <img src={TDWH} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
           </a>
           <p>TDWH</p>
           </div>
           <div>
         <a href="https://kusamelectrical.com/pdf/WATT-%20WATTHOUR%20TRANSDUCER%20MODEL-TDWWH.pdf" target="_blank" rel="noreferrer">
             <img src={TDWWH} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
           </a>
           <p>TDWWH</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/POWER%20FACTOR%20TRANSDUCER%20MODEL-TDPF.pdf" target="_blank" rel="noreferrer">
             <img src={TDPF} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
           </a>
           <p>TDPF</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/POWER%20FREQUENCY%20%20TRANSDUCER%20MODEL-TDF.pdf" target="_blank" rel="noreferrer">
             <img src={TDF} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
           </a>
           <p>TDF</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/HC6610.pdf" target="_blank" rel="noreferrer">
             <img src={HC6610} className="hover"  style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
           </a>
           <p>HC6610</p>
           </div>
         
     </div>
     </div>
     
     </div>
       {/* -------------POWER TRANSDUCERS--------------- */}

{/* -------------CT’S WITH ANALOG OUTPUT--------------- */}
<div ref ={CtWithAnalogOutput} className='ctwithanalogoutput'>
      
      <h3 style={{color:"red"}}>CT’S WITH ANALOG OUTPUT</h3>
             <div>
        <p style={{paddingTop:"2rem" , paddingBottom:"5rem"}} ><BiChevronsRight />Model: TSCT / TFCT - AC Current Isolated Sensor Two / Four Wire Transducer  </p>

         <div className='container grid grid-three-column'>
         <div>
           <a href="https://kusamelectrical.com/pdf/TSCT-TFCT%20with%20analog%20output%20TRANSDUCER%2024-3-17.pdf" target="_blank" rel="noreferrer">
             <img src={TSCT} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>TSCT</p>
           </div>
      
     </div>
     </div>
     
     </div>
       {/* -------------CT’S WITH ANALOG OUTPUT--------------- */}
    
    {/* -------------SIGNAL TRANSMITTER--------------- */}
<div ref ={SignalTransmitters} className='signaltransmitters'>
      
      <h3 style={{color:"red"}}>SIGNAL TRANSMITTERS</h3>
             <div>
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> Model: TW2D - DC Isolating Transmitter  </p>
        <p  ><BiChevronsRight /> Model: TWD - TWD2 - DC Isolating Transmitter  </p>
        <p  ><BiChevronsRight /> Model: TWS / TWS 2 - Current Loop Supply Isolating Transmitter  </p>
        <p  ><BiChevronsRight /> Model: TWR / TWR 2  - RTD Isolating Transmitter </p>
        <p  ><BiChevronsRight /> Model: TWF / TWF2 - Frequency to DC Isolating Transmitter </p>
        <p  ><BiChevronsRight /> Model: TWP / TWP2 - Potentiometer – Level – Position Transmitter </p>
        <p  ><BiChevronsRight /> Model: TW2S - Current Loop Supply Isolating Transmitter  </p>
        <p  ><BiChevronsRight /> Model: TWH - High Selector Isolating Transmitter </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight /> Model: TWL - Low Selector Isolating Transmitter  </p>


         <div className='container grid grid-three-column'>
         <div>
           <a href="https://kusamelectrical.com/pdf/DC%20Isolating%20Transmitter%20with%20Dual%20Input%20&%20Output_TW2D.pdf" target="_blank" rel="noreferrer">
             <img src={TW2D} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>TW2D</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/TWD%20-%20TWD2.pdf" target="_blank" rel="noreferrer">
             <img src={TWD2} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>TWD-TWD2</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/Current%20Loop%20Supply%20Isolating%20Transmitter_TWS%20&%20TWS2.pdf" target="_blank" rel="noreferrer">
             <img src={TWS} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>TWS/TWS2</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/RTD%20Isolating%20Transmitter_TWR%20&%20TWR2.pdf" target="_blank" rel="noreferrer">
             <img src={TWR} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>TWR/TWR2</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/Frequency%20to%20DC%20Isolating%20Transmitter_TWF%20&%20TWF2.pdf" target="_blank" rel="noreferrer">
             <img src={TWF} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>TWF/TWF2</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/PotentiometerLevelPositionTransmitter-TWP-TWP2.pdf" target="_blank" rel="noreferrer">
             <img src={TWP} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>TWP/TWP2</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/TW2S.pdf" target="_blank" rel="noreferrer">
             <img src={TW2S} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>TW2S</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/TWH.pdf" target="_blank" rel="noreferrer">
             <img src={TWH} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>TWH</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/TWL.pdf" target="_blank" rel="noreferrer">
             <img src={TWL} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>TWL</p>
           </div>
           
      
     </div>
     </div>
     
     </div>
       {/* -------------SIGNAL TRANSMITTER--------------- */}
    
      {/* -------------TRANSMITTERS WITH DISPLAY & ALARM OUTPUT--------------- */}
<div ref ={TransmittersWithDisplayAndAlarmOutput} className='transmitterswithdisplayandalarmoutput'>
      
      <h3 style={{color:"red"}}>TRANSMITTERS WITH DISPLAY & ALARM OUTPUT</h3>
             <div>
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> Model TAD - DC Alarm Setter Transmitter  </p>
        <p  ><BiChevronsRight /> Model TAP - Potentiometer Alarm Setter Transmitter  </p>
        <p  ><BiChevronsRight /> Model TAR - RTD Alarm Setter Transmitter  </p>
        <p  ><BiChevronsRight /> Model TAS - Current Loop Supply Alarm Setting Transmitter   </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight /> Model TAT - Thermocouple Alarm Setter Transmitter   </p>


         <div className='container grid grid-three-column'>
         <div>
           <a href="https://kusamelectrical.com/pdf/TAD%20-%20DC%20Alarm%20Setter%20Transmitter.pdf" target="_blank" rel="noreferrer">
             <img src={TAD} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>TAD</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/TAP%20-%20Potentiometer%20Alarm%20Setter%20Transmitter.pdf" target="_blank" rel="noreferrer">
             <img src={TAP} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>TAP</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/TAR%20-%20RTD%20Alarm%20Setter%20Transmitter.pdf" target="_blank" rel="noreferrer">
             <img src={TAR} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>TAR</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/TAS%20-%20Current%20Loop%20Supply%20Alarm%20Setting%20Transmitter.pdf" target="_blank" rel="noreferrer">
             <img src={TAS} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>TAS</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/TAT-THERMOCOUPLE%20ALARM%20SETTER%20TRANSMITTER.pdf" target="_blank" rel="noreferrer">
             <img src={TAT} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>TAT</p>
           </div>
           
     </div>
     </div>
     
     </div>
       {/* -------------TRANSMITTERS WITH DISPLAY & ALARM OUTPUT--------------- */}
    
          {/* -------------OSCILLOSCOPES--------------- */}
          
<div ref ={AnalogStorageOscilloscope} className='analogstorageoscilloscope'>
<h3 style={{color:"red"}}>OSCILLOSCOPES</h3>
      <h3 style={{color:"red"}}>ANALOG STORAGE OSCILLOSCOPE</h3>
             <div>
        <p style={{paddingTop:"2rem" , paddingBottom:"5rem"}} ><BiChevronsRight /> Model: KM-5060 - 60 MHz Dual Trace Analog Oscilloscope </p>
      


         <div className='container grid grid-three-column'>
         <div>
           <a href="https://kusamelectrical.com/pdf/KM-5060%20Oscilloscope.pdf" target="_blank" rel="noreferrer">
             <img src={KM5060} className="hover"  style={{ width: 150, height: 90 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM5060</p>
           </div>
          
     </div>
     </div>
     
     </div>

     <div ref ={DigitalStorageOscilloscope} className='digitalstorageoscilloscope'>
      
      <h3 style={{color:"red"}}>DIGITAL STORAGE OSCILLOSCOPE</h3>
             <div>
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> Model: SSA3032X Spectrum Analyser    </p>
        <p  ><BiChevronsRight /> Model: KM 2025CL / KM 2052CL+ - 25MHz / 50MHz Digital Storage Oscilloscope   </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight /> Model: KM 2102CEX+ - 100MHz Digital Storage Oscilloscope    </p>

         <div className='container grid grid-three-column'>
         <div>
           <a href="https://kusamelectrical.com/pdf/SSA%203032X%20spectrum%20analyser.pdf" target="_blank" rel="noreferrer">
             <img src={SSA3032X} className="hover"  style={{ width: 150, height: 90 }}alt="Coding Beauty logo"></img>
           </a>
           <p>SSA3032X</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/KM%202025CL%20&%20KM%202052CL+.pdf" target="_blank" rel="noreferrer">
             <img src={KM2025CL} className="hover"  style={{ width: 150, height: 90 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM2025CL / KM2052CL+</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/KM%202102CEX+.pdf" target="_blank" rel="noreferrer">
             <img src={KM2102CEX} className="hover"  style={{ width: 150, height: 90 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM 2102 CEX+</p>
           </div>
          
     </div>
     </div>
     
     </div>
       {/* -------------OSCILLOSCOPES--------------- */}
    


    {/* -------------POWER SUPPLIES--------------- */}
<div ref ={PowerSupplies} className='powersupplies'>
      
      <h3 style={{color:"red"}}>POWER SUPPLIES</h3>
             <div>
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight />Model: KM PS 302 / KM PS 303 / KM PS 305 - DC Single output Digital Power Supply   </p>
        <p  ><BiChevronsRight /> Model: KM PS 302D-II / KM PS 303D-II / KM PS 305D-II - DC Dual output Digital Power Supply  </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight /> Model: KM PS 3010 AB - DC Regulated Digital Power Supply  </p>


         <div className='container grid grid-three-column'>
         <div>
           <a href="https://kusamelectrical.com/pdf/KM-PS-302-303-305%20NEW.pdf" target="_blank" rel="noreferrer">
             <img src={KMPS302} className="hover"  style={{ width: 150, height: 90 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM-PS-302 / KM-PS-303 / KM-PS-305</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/DC%20Power%20Supply%20KM-PS-302-303-305D-II.pdf" target="_blank" rel="noreferrer">
             <img src={KMPS302D2} className="hover"  style={{ width: 150, height: 90 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM PS 302D-II /303D-II/305D-II</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/KM-PS-3010AB.pdf" target="_blank" rel="noreferrer">
             <img src={KMPS3010AB} className="hover"  style={{ width: 150, height: 100 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM-PS-3010AB</p>
           </div>
         
           
     </div>
     </div>
     
     </div>
       {/* -------------POWER SUPPLIES--------------- */}
    


    {/* -------------WATERPROOF PEN TESTERS--------------- */}
    <div ref ={WaterproofPenTesters} className='waterproofpentesters'>
      
      <h3 style={{color:"red"}}>WATERPROOF PEN TESTERS</h3>
             <div>
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight />Model : 6011, 6011A, 6021, 6022, 6031, 6032, 6061 - Waterproof Pen Tester   </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight /> Model: 7000 - Waterproof Pen Tester  pH/ORP/TEMP/COND/TDS PEN Tester   </p>
             
         <div className='container grid grid-three-column'>
         <div>
           <a href="https://kusamelectrical.com/pdf/waterproof%20Pen%20Tester%206000%20SERIES.pdf" target="_blank" rel="noreferrer">
             <img src={MODEL6011} className="hover"  style={{ width: 150, height: 90 }}alt="Coding Beauty logo"></img>
           </a>
           <p>6011, 6011A, 6021, 6022, 6031, 6032, 6061</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/waterproof%20Pen%20Tester%20%207000%20SERIES.pdf" target="_blank" rel="noreferrer">
             <img src={MODEL7000} className="hover"  style={{ width: 150, height: 90 }}alt="Coding Beauty logo"></img>
           </a>
           <p>7000</p>
           </div>      
           
     </div>
     </div>
     
     </div>
       {/* -------------WATERPROOF PEN TESTERS--------------- */}
    

    {/* -------------PORTABLE THERMAL IMAGING CAMERA--------------- */}
    <div ref ={PortableThermalImagingCamera} className='portablethermalimagingcamera'>
      
      <h3 style={{color:"red"}}>PORTABLE THERMAL IMAGING CAMERA</h3>
             <div>
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> Model: TG-301 - Handheld Thermal Camera    </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight /> Model: TG-305 - Thermal Imaging Camera    </p>

         <div className='container grid grid-three-column'>
         <div>
           <a href="https://kusamelectrical.com/pdf/TG-301.pdf" target="_blank" rel="noreferrer">
             <img src={TG301} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>TG-301</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/TG-305.pdf" target="_blank" rel="noreferrer">
             <img src={TG305} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>TG-305</p>
           </div>      
           
     </div>
     </div>
     
     </div>
       {/* -------------PORTABLE THERMAL IMAGING CAMERA--------------- */}
    
   {/* -------------CABLE FAULT PRE-LOCATOR--------------- */}
   <div ref ={CableFaultPreLocator} className='cablefaultprelocator'>
      
      <h3 style={{color:"red"}}>CABLE FAULT PRE-LOCATOR</h3>
             <div>
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> Model: KM-CFPL-620 - Cable fault locator    </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight /> Model: KM-CFPL-612 - Cable fault locator </p>

         <div className='container grid grid-three-column'>
         <div>
           <a href="https://kusamelectrical.com/pdf/KM-CFPL-620.pdf" target="_blank" rel="noreferrer">
             <img src={KMCFPL620} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM-CFPL-620</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/KM-CFPL-612.pdf" target="_blank" rel="noreferrer">
             <img src={KMCFPL612} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KMCFPL612</p>
           </div>      
           
     </div>
     </div>
     
     </div>
       {/* -------------CABLE FAULT PRE-LOCATOR--------------- */}
    
  {/* -------------BATTERY QUALITY ANALYZER--------------- */}
  <div ref ={BatteryQualityAnalyzer} className='batteryqualityanalyzer'>
      
      <h3 style={{color:"red"}}>BATTERY QUALITY ANALYZER</h3>
             <div>
        <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> Model: KM-900 - Battery Quality Analyzer   </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight /> Model: KM-930 - Battery Internal Resistance Tester    </p>

         <div className='container grid grid-three-column'>
         <div>
           <a href="https://kusamelectrical.com/pdf/KM%20900.pdf" target="_blank" rel="noreferrer">
             <img src={KM900} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM900</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/KM%20930.pdf" target="_blank" rel="noreferrer">
             <img src={KM930} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM930</p>
           </div>      
           
     </div>
     </div>
     
     </div>
       {/* -------------BATTERY QUALITY ANALYZER--------------- */}
    
 {/* -------------HIGH VOLTAGE TEST EQUIPMENTS--------------- */}
 <div ref ={HighVoltageTestEquipments} className='highvoltagetestequipments'>
      
      <h3 style={{color:"red"}}>HIGH VOLTAGE TEST EQUIPMENTS</h3>
             <div>
             <p style={{paddingTop:"2rem"}} ><BiChevronsRight />Model: KM-HVBT-3, KM-HVBT-5, KM-HVBT-10, KM-HVBT-15 - High Voltage Breakdown Tester     </p>
        <p ><BiChevronsRight /> Model : KM-trm-1 - Turns Ration Meter    </p>
        <p ><BiChevronsRight /> Model : KM-RTS - Relay Test Set / Secondary Injection Test Set    </p>
        <p ><BiChevronsRight /> Model : KM-cbt-01 / KM-cbt-02 - Circuit Breaker Timer   </p>
        <p  ><BiChevronsRight /> Model: KM-8500-100 - Digital Contact Resistance Meter (Micro - Ohm Meter)   </p>
        <p style={{paddingBottom:"5rem"}} ><BiChevronsRight /> Model: KM-8500-1/KM-8500-10 - Digital Micro - Ohm Meter    </p>

         <div className='container grid grid-three-column'>
         <div>
           <a href="https://kusamelectrical.com/pdf/high-voltage-breakdown-tester-KM-%20HVBT-3KM-%20HVBT-5KM-%20HVBT-10%20KM-%20HVBT-15.pdf" target="_blank" rel="noreferrer">
             <img src={KMHVBT3} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM-HVBT-3,KM-HVBT-5,KM-HVBT-10,KM-HVBT-15</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/Turns%20ratio%20meter%20Model%20KM-TRM-1.pdf" target="_blank" rel="noreferrer">
             <img src={KMTRM1} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KMTRM1</p>
           </div>   
           <div>
           <a href="https://kusamelectrical.com/pdf/Relay%20Test%20Set%20KM-RTS.pdf" target="_blank" rel="noreferrer">
             <img src={KMRTS} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KMRTS</p>
           </div> 
           <div>
           <a href="https://kusamelectrical.com/pdf/Circuit%20Breaker%20Timer%20KM-CBT-01-02.pdf" target="_blank" rel="noreferrer">
             <img src={KMCBT01} className="hover"  style={{ width: 150, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM-CBT-01 / KM-CBT-02</p>
           </div> 
           <div>
           <a href="https://kusamelectrical.com/pdf/Digital%20Contact%20Resistance%20Meter%20Model%20KM-%20100.....8500.pdf" target="_blank" rel="noreferrer">
             <img src={KM8500100} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM-8500-100</p>
           </div> 
           <div>
           <a href="https://kusamelectrical.com/pdf/Digital%20micro%20OHM%20meter%20KM-8500-1,%20km-8500-10.pdf" target="_blank" rel="noreferrer">
             <img src={KM85001} className="hover"  style={{ width: 150, height: 100 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM85001/ KM8500-10</p>
           </div>    
           
     </div>
     </div>
     
     </div>
       {/* -------------HIGH VOLTAGE TEST EQUIPMENTS--------------- */}
    
    
 {/* -------------MICRO /MILLI OHM METER --------------- */}
 <div ref ={MicroMilliOhmMeter} className='micromilliohmmeter'>
      
      <h3 style={{color:"red"}}>MICRO /MILLI OHM METER </h3>
             <div>
             <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> Model - KM 8501 - Digital DC Low Resistance Meter </p>
        <p ><BiChevronsRight /> Model : KM 8502  Handheld Dc Resistance Tester (10a) </p>
        <p style={{paddingBottom:"5rem"}}><BiChevronsRight /> Model : Km-8500-1 / KM-8500-10 - Digital Micro - Ohm Meter  </p>
        
                 <div className='container grid grid-three-column'>
         <div>
           <a href="https://kusamelectrical.com/pdf/KM%20OHM%208501.pdf" target="_blank" rel="noreferrer">
             <img src={KMOHM8501} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KMOHM8501</p>
           </div>
           <div>
           <a href="https://kusamelectrical.com/pdf/KM%20OHM%208502.pdf" target="_blank" rel="noreferrer">
             <img src={KMOHM8502} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KMOHM8502</p>
           </div>   
           <div>
           <a href="https://kusamelectrical.com/pdf/KM-8500-1%20&%20km-8500-10.pdf" target="_blank" rel="noreferrer">
             <img src={KM850012} className="hover"  style={{ width: 150, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM-8500-1/KM-8500-10</p>
           </div> 
           <div>
           <a href="https://drive.google.com/file/d/16PdgCWhKXg5VmCM784GNuM4DAK3FtF_8/view?usp=drive_link" target="_blank" rel="noreferrer">
             <img src={MICROOHMETER114} className="hover"  style={{ width: 150, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>MICROOHMETER-114</p>
           </div> 
            
           
     </div>
     </div>
     
     </div>
       {/* -------------MICRO /MILLI OHM METER --------------- */}
    

    
 {/* -------------WRIST TYPE HIGH VOLTAGE ALARM--------------- */}
 {/* <div ref ={WristTypeHighVoltageAlarm} className='wristtypehighvoltagealarm'>
      
      <h3 style={{color:"red"}}>WRIST TYPE HIGH VOLTAGE ALARM</h3>
 
             <div>
             <p style={{paddingTop:"2rem", paddingBottom:"5rem"}} ><BiChevronsRight /> Model - KM-HVW-289 WRIST TYPE HIGH VOLTAGE ALARM</p>

                 <div className='container grid grid-three-column'>
         <div>
           <a href="https://drive.google.com/file/d/1gbmO7rbkrLkgYxcErGx_6Q6VXNUsuD1I/view?usp=sharing" target="_blank" rel="noreferrer">
             <img src={KMHVW289} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM-HVW-289</p>
           </div>
           
     </div>
     </div>
     
     </div> */}
       {/* -------------WRIST TYPE HIGH VOLTAGE ALARM --------------- */}


 {/* -------------DIGITAL DISPLAY TORQUE WRENCH--------------- */}
 <div ref ={TorqeuWrench} className='torqeuwrench'>
      
      <h3 style={{color:"red"}}>TORQUE WRENCH </h3>
             <div>
             <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> Model - KM-NG-60 DIGITAL DISPLAY TORQUE WRENCH</p>
             <p  ><BiChevronsRight /> Model - KM-NGM-10 MINI DIGITAL TORQUE WRENCH</p>
             <p  ><BiChevronsRight /> Model - KM-NGM-30 MINI DIGITAL TORQUE WRENCH</p>
             <p  ><BiChevronsRight /> Model - KM-NGM-100 MINI DIGITAL TORQEU WRENCH</p>
             <p style={{ paddingBottom:"5rem"}} ><BiChevronsRight /> Model - KM-NGM-200 DIGITAL DISPLAY TORQEU WRENCH</p>


                 <div className='container grid grid-three-column'>
         <div>
           <a href="https://drive.google.com/file/d/1vG9GBIQASU-Ozky36jSqlKmxhdr4249_/view?usp=sharing" target="_blank" rel="noreferrer">
             <img src={KMNG60} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM-NG-60</p>
           </div>
           <div>
           <a href="https://drive.google.com/file/d/14wu17XlAcNiT152ppQSQ_vFWbWA7QgFy/view?usp=sharing" target="_blank" rel="noreferrer">
             <img src={KMNGM10} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM-NGM-10</p>
           </div>
           <div>
           <a href="https://drive.google.com/file/d/1VWJA20I0VkpsXhrs75XCbY49aJDnFSMt/view?usp=sharing" target="_blank" rel="noreferrer">
             <img src={KMNGM30} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM-NGM-30</p>
           </div>
           <div>
           <a href="https://drive.google.com/file/d/1Vmr39qGv_bXQdA3Wbx4dTZ7JOXICocQ-/view?usp=sharing" target="_blank" rel="noreferrer">
             <img src={KMNGM100} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM-NGM-100</p>
           </div>
           <div>
           <a href="https://drive.google.com/file/d/1BtMuX6-XmZGVGJrZLpJlOMqILQHFjFGJ/view?usp=sharing" target="_blank" rel="noreferrer">
             <img src={KMNGM200} className="hover"  style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM-NGM-200</p>
           </div>
            
           
            
           
     </div>
     </div>
     
     </div>
       {/* -------------DIGITAL DISPLAY TORQUE WRENCH --------------- */}

{/* DIGITAL CALIPER */}

<div ref ={DigitalCaliper} className='digitalcaliper'>
      
      <h3 style={{color:"red"}}>DIGITAL CALIPER </h3>
             <div>
             <p style={{paddingTop:"2rem"}} ><BiChevronsRight /> Model - KM-VC-5200 DIGITAL CALIPER</p>
             <p style={{ paddingBottom:"5rem"}} ><BiChevronsRight /> Model - KM-VC-5300 DIGITAL VERNIER CALIPER</p>
                 <div className='container grid grid-three-column'>
         <div>
           <a href="https://drive.google.com/file/d/10BPEILwJwOCZadHm21d3SWbpOXH_QR6e/view?usp=sharing" target="_blank" rel="noreferrer">
             <img src={KMVC5200} className="hover"  style={{ width: 150, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM-VC-5200</p>
           </div>
           <div>
           <a href="https://drive.google.com/file/d/1VoSkKUmgSgLOljgxQ6IyhXQFzw460drJ/view?usp=sharing" target="_blank" rel="noreferrer">
             <img src={KMVC5300} className="hover"  style={{ width: 150, height: 150 }}alt="Coding Beauty logo"></img>
           </a>
           <p>KM-VC-5300</p>
           </div>
         
           
     </div>
     </div>
     
     </div>
{/* DIGITAL CALIPER */}




















    {/* <div>
    <h2>Digital Multimeters</h2>

    <div className='container grid grid-three-column'>
    <div><a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSN3QxZ2FDM2dqdVE/view?resourcekey=0-GX3G6c5uDaA_wFORgB7GAw" target="_blank" rel="noreferrer">
        <GridListTile>
        <img src={cbLogo} style={{ width: 90, height: 150, alignSelf: 'flex-end' }} alt="Coding Beauty logo"></img>
        <GridListTileBar
            title='KM829'
            // subtitle={<span>by: GeeksforGeeks</span>}
            // actionIcon={
            //   <IconButton aria-label={`Best Place to learn`}>
            //     <InfoIcon />
            //   </IconButton>
            // }
          />
          </GridListTile>
      </a>
      <p>Km869</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSZXBaOWZpYllEY3M/view?resourcekey=0-pAb7fwq6f9igtQmOWQuLJQ" target="_blank" rel="noreferrer">
        <img src={cbLogo} style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM829</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSdGMzT2gtWGRnanc/view?resourcekey=0-VQhuHEXz7t3m17dcXLGKEA" target="_blank" rel="noreferrer">
        <img src={cbLogo} style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM829</p>
      </div>

      <div>
      <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sScjNwR0VFS3pVODQ/view?resourcekey=0-jmCHyxJtxOE6xnaOSu4YVw" target="_blank" rel="noreferrer">
        <img src={cbLogo} style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM525-551</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSRTdzT1h5MlU5NVE/view?resourcekey=0-lUEuNo_0XQc2PX_5kwHFow" target="_blank" rel="noreferrer">
        <img src={cbLogo} style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM859 CF</p>
      </div>
      <div>
      <a href="https://drive.google.com/file/d/0Bz1iBRbmM1sSbXdPdno4YWZrelk/view?resourcekey=0-3JHeq1tL2z8IaBOjMNCW7w" target="_blank" rel="noreferrer">
        <img src={cbLogo} style={{ width: 90, height: 150 }}alt="Coding Beauty logo"></img>
      </a>
      <p>KM515</p>
      </div>
     
      
</div>

 */}
 </div>

</Wrapper>


  )
}


const Wrapper = styled.section`
padding:1 rem 0
.hover{
  
  transition: .3s ease
}
.hover:hover{
  width:2rem;
  height:2rem;
  transform: scale(1.5);
}
${'' /* .App{
  text-align:center;
  } */}
  .hero{
    height:2200px;
    background-color: #F6F8FA;

  }
  h1{color: black;}
  h3{color: blue;
  padding-bottom: 25px;
  }
  .services{
    height:3300px;
    background-color: #F6F8FA;
    color: red;
  }
 
  .begin{
    color: red;
  }
  .blog{
    height:1800px;
    background-color: #F6F8FA;
    color: blue;
    padding-bottom: 20%;
  }
 
  ${'' /* .padder{
    padding-top:500px
  }
  .short{
    padding-top:500px
  } */}
  .contact{
    height:1100px;
    background-color: #F6F8FA;
  }
  .lcrmeter{
    height:1200px;
    background-color: #F6F8FA;
  }
  .servicesclampmeter{
    height:2300px;
    background-color: #F6F8FA;
  }
  .professionalclampmeter{
    height:2600px;
    background-color: #F6F8FA;
  }
  .industrialclampmeter{
    height:1700px;
    background-color: #F6F8FA;
  }
  
  .earthresistancetester{
    height:2500px;
    background-color: #F6F8FA;
  }
  .insulationresistancetester{
    height:2200px;
    background-color: #F6F8FA;
  }
  .highvoltagmeasuringinstruments{
    height:3600px;
    background-color: #F6F8FA;
  }
  .coatingthicknessguage{
    height:700px;
    background-color: #F6F8FA;
  }
  .environmentaltstinginstruments{
    height:6200px;
    background-color: #F6F8FA;
  }
  .elcbtester{
    height:600px;
    background-color: #F6F8FA;
  }
  .laboratoryinstruments{
    height:1900px;
    background-color: #F6F8FA;
  }

  .digitalanemometers{
    height:500px;
    background-color: #F6F8FA;
  }
  .digitalluxmeter{
    height:600px;
    background-color: #F6F8FA;
  }

  .infraredthermometers{
    height:500px;
    background-color: #F6F8FA;
  }
  .powermeasurementclampmeters{
    height:1000px;
    background-color: #F6F8FA;
  }
  .powermeasurementandcontrolinstruments{
    height:1700px;
    background-color: #F6F8FA;
  }
  .powerfactorregulator{
    height:550px;
    background-color: #F6F8FA;
  }
  .programmabledigitalpanelmeters{
    height:3600px;
    background-color: #F6F8FA;
  }
  .calibrators{
    height:2500px;
    background-color: #F6F8FA;
  }
  .gasanalyser{
    height: 2300px;
    background-color: #F6F8FA;
  }
  .recordersanalyser{
    height:800px;
    background-color: #F6F8FA;
  }
  .currencycounting{
    height:1100px;
    background-color: #F6F8FA;
  }
  .singlephase{
    height:1000px;
    background-color: #F6F8FA;
  }
  .threephase{
    height:1000px;
    background-color: #F6F8FA;
  }

  .telescopicearth{
    height:1000px;
    background-color: #F6F8FA;
  }
  .powertransducers{
    height:1500px;
    background-color: #F6F8FA;
  }
  .ctwithanalogoutput{
    height:450px;
    background-color: #F6F8FA;
  }
  .signaltransmitters{
    height:1200px;
    background-color: #F6F8FA;
  }
  .transmitterswithdisplayandalarmoutput{
    height:800px;
    background-color: #F6F8FA;
  }
  .oscilloscopes{
    height:1000px;
    background-color: #F6F8FA;
  }
  .analogstorageoscilloscope{
    height:500px;
    background-color: #F6F8FA;
  }
  .digitalstorageoscilloscope{
    height:500px;
    background-color: #F6F8FA;
  }
  .powersupplies{
    height:500px;
    background-color: #F6F8FA;
  }
  .waterproofpentesters{
    height:500px;
    background-color: #F6F8FA;
  }
  .portablethermalimagingcamera{
    height:500px;
    background-color: #F6F8FA;
  }
  .cablefaultprelocator{
    height:500px;
    background-color: #F6F8FA;
  }
  .batteryqualityanalyzer{
    height:350px;
    background-color: #F6F8FA;
  }
  .highvoltagetestequipments{
    height:900px;
    background-color: #F6F8FA;
  }
  .micromilliohmmeter{
    height:750px;
    background-color: #F6F8FA;
  }
  .wristtypehighvoltagealarm{
    height:750px;
    background-color: #F6F8FA;
  }
  .torqeuwrench{
    height:1000px;
    background-color: #F6F8FA;
  }
  .digitalcaliper{
    height:750px;
    background-color: #F6F8FA;
  }
  .head{
    
    text-decoration:none;
    display:flex;
    padding:5px 5px;
    font-weight: 700;
    font-size: 3.2rem;
    cursor:pointer;
    color:blue;
    align-self:flex-start;
  }
  .link{
    text-decoration:none;
    ${'' /* display:inline; */}
    padding:5px 5px;
    font-weight: 700;
    font-size: large;
    cursor:pointer;
    color:blue;
    display:flex;
    align-self:flex-start;

    .grid-filter-column {
    grid-template-columns: 0.2fr 1fr;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .grid-filter-column {
      grid-template-columns: 1fr;
    }
  }
  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    html{
      font-size:20%
    }
    .grid-three-column {
      grid-template-columns: 1fr;
    }
  }
  }






  `


export default Products
