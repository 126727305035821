import { NavLink } from "react-router-dom";
import styled from "styled-components";
// import { Button } from "../styles/components/Button";
import {Button} from "../Button"
const HeroSection1 = ({ myData }) => {
  const { name } = myData;

  return (
    <Wrapper>
      <div className="container">
        <div className="grid grid-two-column">
          <div className="hero-section-data">
            <p className="intro-data">Hi Bharath </p>
            <h2> {name} </h2>
            <p>
            Microdyne Systems has the wide A to Z range of electronic and electrical testing and manufacturing instruments for different applications in small scale,medium and high sector industries and educational institutions and defence etc.
            Our Proprietor Mr. Bharath Shah has more than 3 decades of vast experience in this field of electronics and electronic testing and measuring.</p>
            <h3>QUALITY ASSURANCE:</h3>
            <p>Microdyne system is a quality centric company and are committed to provide products that are highly reliable and easy to operate. All the products are thoroughly checked before dispatch.</p>
            <h3>CLIENT SATISFACTION</h3>
            <p>Microdyne Systems is a client oriented firm, giving more prominence to client satisfaction by offering quality products and services. All our endeavors are focused to implement client-centric approach and set industry standards in our processes. Our ethical business practice, flexible approach towards customers have made us a preferable business associates of our customers.</p>
            {/* <NavLink>
              <Button>show now</Button>
            </NavLink>  */}
          </div>
         

         
          <div className="hero-section-image">
            <figure>
              <img
                src="images/MultimeterClapmeter.png"
                alt="hero-section-photo"
                className="img-style"
              />
            </figure>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 4rem 0;

  img {
    min-width: 10rem;
    height: 10rem;
  }

  .hero-section-data {
    p {
      margin: 2rem 0;
    }

    h1 {
      text-transform: capitalize;
      font-weight: bold;
    }

    .intro-data {
      margin-bottom: 0;
    }
  }

  .hero-section-image {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  figure {
    position: relative;

    &::after {
      content: "";
      width: 60%;
      height: 80%;
      background-color: rgba(81, 56, 238, 0.4);
      position: absolute;
      left: 50%;
      top: -5rem;
      z-index: -1;
    }
  }
  .img-style {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .grid {
      gap: 10rem;
    }

    figure::after {
      content: "";
      width: 50%;
      height: 100%;
      left: 0;
      top: 10%;
      /* bottom: 10%; */
      background-color: rgba(81, 56, 238, 0.4);
    }
  }
`;

export default HeroSection1;