// import React from 'react'

// const About = () => {
//   return (
//     <div>
//        <p>About Page</p>
//        <img src='./images/logo2.png' alt="my logo img"/>
//    <p>About</p>
//     </div>
//   )
// }

// export default About

import HeroSection1 from "./HeroSection1";

import { useProductContext } from "../../context/productcontext"
const About = () => {
  const { myName } = useProductContext();

  const data = {
    name: "Microdyne Systems",
  };

  return (
    <>
      {myName}
      <HeroSection1 myData={data} />
    </>
  );
};

export default About;
