import firebase from 'firebase/compat/app'

const firebaseApp = firebase.initializeApp({
    // apiKey: "AIzaSyC_730ZmO8rOnJv02KEBnaydc5d9KlnwLA",
    // authDomain: "firechat-eb0f5.firebaseapp.com",
    // projectId: "firechat-eb0f5",
    // storageBucket: "firechat-eb0f5.appspot.com",
    // messagingSenderId: "855670603066",
    // appId: "1:855670603066:web:a9bcf2477bcbd1045da403",
    // measurementId: "G-GEJDXM80CV"
    apiKey: "AIzaSyBBh2GCpA1LFu4gWvAolHVp18MbQkDJbnM",

  authDomain: "shining-glyph-307621.firebaseapp.com",

  databaseURL: "https://shining-glyph-307621-default-rtdb.firebaseio.com",

  projectId: "shining-glyph-307621",

  storageBucket: "shining-glyph-307621.appspot.com",

  messagingSenderId: "372111186576",

  appId: "1:372111186576:web:6e4cf79b516c9ab6341615",

  measurementId: "G-WVFLG1ZQ83"

})

const db = firebaseApp.firestore()

const auth = firebase.auth()

export { db, auth }