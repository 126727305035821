import React from 'react'
import { auth } from './firebase'
// import { Button } from '@material-ui/core'

function SignOut() {
    return (
        <div style={{
            display: 'flex', justifyContent: 'center', position: 'fixed',height:"70px", width: '100%',  top: "60px",  opacity: 2, zIndex: '10'
        }}>
            <button style={{ padding: '20px', fontSize: '15px', borderRadius: '100px', fontWeight: '600', alignSelf:"center" ,color:"white"}} onClick={() => auth.signOut()}>Sign Out</button>
        </div>
    )
}

export default SignOut
