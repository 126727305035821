import React from "react";
// import logo from './logo.svg';
import './App.css';
// import img1 from './images/FlugTicket.jpeg'
// import Navbar from'./components/Navabar'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../src/styles/components/Home';
import About from '../src/styles/components/About';
import Products from '../src/styles/components/Products';
import Contact from '../src/styles/components/Contact';
import Support from '../src/styles/components/Support';

import SingleProduct from '../src/styles/components/SingleProduct';
import Cart from '../src/styles/components/Cart';
import Error from '../src/styles/components/Error';
import Header from "./styles/components/Header";
import {GlobalStyle} from "./GlobalStyle"

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from "styled-components";
import styled from "styled-components"
import Footer from "./styles/components/Footer";

// import Chat from './styles/components/Chat';
// import SignIn from './styles/components/SignIn';
import { auth } from './styles/components/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'

const App = () => {
  const [user] = useAuthState(auth)
  const theme = {
    colors: {
      heading: "rgb(24 24 29)",
      text: "rgba(29 ,29, 29, .8)",
      white: "#fff",
      black: " #212529",
      helper: "#8490ff",

      bg: "#F6F8FA",
      footer_bg: "#0a1435",
      btn: "rgb(98 84 243)",
      border: "rgba(98, 84, 243, 0.5)",
      hr: "#ffffff",
      gradient:
        "linear-gradient(0deg, rgb(132 144 255) 0%, rgb(98 189 252) 100%)",
      shadow:
        "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",
      shadowSupport: " rgba(0, 0, 0, 0.16) 0px 1px 4px",
    },
    media: {
      mobile: "768px",
      tab: "998px",
    },
  };

  return (
    <ThemeProvider theme={theme}>
    <Router>
  <GlobalStyle/>
  <Header/>
  {/* <section className='hero-section'>
        <p>Welcome to </p>
        <h1>Microdyne Systems</h1>
    </section> */}
    <ToastContainer
position="top-center"
autoClose={6000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="dark"
/>
   

    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/products" element={<Products />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/support" element={<Support />} />
      <Route path="/singleproduct/:id" element={<SingleProduct />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="*" element={<Error />} />
    </Routes>
    <Footer/>
  </Router>
  </ThemeProvider>
  )
};
const MainHeader = styled.header`
 .hero-section{
    background-image: url("./images/MultimeterClapmeter.jpg");
    background-size:cover;
    color: #fff;

  }`
export default App;
