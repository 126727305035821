import React, { useState, useEffect, useRef } from 'react'
import { db, auth } from '../components/firebase'
import SendMessage from './SendMessage'
import SignOut from './SignOut'
import firebase from 'firebase/compat/app'
// const admin = require('firebase-admin');
function Chat() {
    const scroll = useRef()
    const [messages, setMessages] = useState([])
    // var email = firebase.auth().currentUser && firebase.auth().currentUser.email;
    useEffect(() => {
        db.collection('messages').orderBy('createdAt').limit(50).onSnapshot(snapshot => {
            setMessages(snapshot.docs.map(doc => doc.data()))
       
       })
    }, [])
    return (
        <div>
            <SignOut />
            <div className="msgs">
                {messages.map(({ id, text, photoURL, uid , email}) => (
                    <div>
                        <div key={id} className={`msg ${uid === auth.currentUser.uid ? 'sent' : 'received'}`}>
                        <p>{email}</p> <br></br>
                            <img style={{  width: "40px", height: "40px",margin: "2px 5px",borderRadius:"50%"}} 
                            src={photoURL} alt="" /><br></br>
                          
                            <p>{text}</p><br></br>
                        </div>
                    </div>
                ))}
            </div>
            <SendMessage scroll={scroll} />
            <div ref={scroll}></div>
        </div>
    )
}

export default Chat
