import React from 'react'
import styled from "styled-components"
// import { NavLink } from 'react-router-dom'
import Nav from './Nav'

const Header = () => {
  return <MainHeader>
   <div className="logo">
        <h2 className='logostart'> Microdyne Systems</h2>
        </div>
    {/* <NavLink to="/"> */}
    {/* <h2>Microdyne Systems</h2> */}
   
    {/* <img src='./images/logo4.png' alt="my logo img"/> */}
    
    {/* </NavLink> */}
    <Nav />

  </MainHeader>

  
}

const MainHeader = styled.header`
padding: 2rem 1rem ;
height : 10rem;
background-color: ${({theme}) => theme.colors.bg};
display:flex;
${'' /* display: findByLabelText; */}
 justify-content:space-between;
align-items:clearInterval;
position:relative;

${'' /* justify-content:flex-end;
align-items:center;
position:relative; */}
${'' /* .logo{
  display:flex;
  justify-content:flex-end;
align-items:center;

} */}
h2 {
   font-family: "Work Sans", sans-serif;
   font-size: 3.2rem;
   ${'' /* display:flex; */}
   ${'' /* align-self: start; */}
${'' /* justify-content:flex-start;
align-items:center;
 */}
justify-self:start;

}
.logo h2 span {
  font-size: 3.5rem;
}
${'' /* .logo{
    height:15rem;
} */}

.hero-section{
    background-image: url("../images/MultimeterClapmeter.jpg");
    background-size:cover;
    color: #fff;

  }

`

export default Header
