import React from 'react'

const SingleProduct = () => {
  return (
    <div>
    <p>Single Product</p>
    </div>
  )
}

export default SingleProduct
