import { NavLink } from "react-router-dom";
import styled from "styled-components";
// import { Button } from "../styles/components/Button";
import {Button} from "../Button"
const HeroSection = ({ myData }) => {
  const { name } = myData;

  return (
    <Wrapper>
      <div className="container">
        <div className="grid grid-two-column">
          <div className="hero-section-data">
            <p className="intro-data">Welcome to </p>
            <h2> {name} </h2>
            <p>We are one of the Leading Manufacturers & Stockists of Electronic Testing & Measuring Instruments which includes Digital Multimeters, Clampmeters, Calibrators & etc.            </p>
            <p>We are dealing in a wide range of products which include Calibrators, Clampmeters, counting Machines, Environmental Testing Instruments, Gas Analyser, HV Measuring Instruments, Insulation and Earth Testers, LCR Meters, Laboratory instruments, Power Measurement instruments, Power Transducers, Sound Level Meters, Test and Measuring Instruments & other products.</p>
            {/* <NavLink>
              <Button>show now</Button>
            </NavLink>  */}
          </div>
         
{/* changed because of git */}
         
          <div className="hero-section-image">
            <figure>
              <img
                src="images/MultimeterClapmeter.png"
                alt="hero-section-photo"
                className="img-style"
              />
            </figure>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  padding: 4rem 0;

  img {
    min-width: 10rem;
    height: 10rem;
  }

  .hero-section-data {
    p {
      margin: 2rem 0;
    }

    h1 {
      text-transform: capitalize;
      font-weight: bold;
    }

    .intro-data {
      margin-bottom: 0;
    }
  }

  .hero-section-image {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  figure {
    position: relative;

    &::after {
      content: "";
      width: 60%;
      height: 80%;
      background-color: rgba(81, 56, 238, 0.4);
      position: absolute;
      left: 50%;
      top: -5rem;
      z-index: -1;
    }
  }
  .img-style {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${({ theme }) => theme.media.mobile}) {
    .grid {
      gap: 10rem;
    }

    figure::after {
      content: "";
      width: 50%;
      height: 100%;
      left: 0;
      top: 10%;
      /* bottom: 10%; */
      background-color: rgba(81, 56, 238, 0.4);
    }
  }
`;

export default HeroSection;